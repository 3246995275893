<div class="d-block">
    <canvas baseChart width="400" height="100"
            [datasets]="lineChartData"
            [labels]="lineChartLabels"
            [options]="lineChartOptions"
            [colors]="lineChartColors"
            [legend]="lineChartLegend"
            [chartType]="lineChartType"
            [plugins]="lineChartPlugins"
            (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)"></canvas>
  </div>
  <div class="table-responsive">
    <table class="table mt-3 table-condensed table-hover table-bordered">
      <thead>
      <tr>
        <th class="text-center" *ngFor="let label of lineChartLabels">{{label}}</th>
      </tr>
      </thead>
      <tr *ngFor="let d of lineChartData; let i=index" [class]="'line-'+i">
        <td class="text-center" *ngFor="let label of lineChartLabels; let j=index">{{d && d.data[j]}}</td>
      </tr>
    </table>
  </div>
  