<div [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHover()"
     (mouseout)="sidebarHover()">
  <div class="app-header__logo">
    <div class="logo-src"></div>
    <div class="header__pane ml-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
              [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar()">
        <span class="hamburger-box">
            <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true">
        <div class="v-sidebar-menu vsm-default">
          <div class="vsm-list">
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <!-- <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">Administrador</div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="dashboardsMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-graph2"></i>
                    <span class="vsm-title">Dashboards</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item"><a routerLink="/panda/chart-global" routerLinkActive="active-item"
                                               [routerLinkActiveOptions]="{exact: true}" class="vsm-link"><span
                        class="vsm-title">Global</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/panda/chart-units" class="vsm-link"> <span
                          class="vsm-title">Por unidad</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/panda/chart-places" class="vsm-link"> <span
                          class="vsm-title">Por establecimiento</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="settingsMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-settings"></i>
                    <span class="vsm-title">Configuraciones</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/dropdowns" class="vsm-link"> <span
                          class="vsm-title">Unidades</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/dropdowns" class="vsm-link"> <span
                          class="vsm-title">Evaluaciones</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/dropdowns" class="vsm-link"> <span
                          class="vsm-title">Misiones</span></a>
                      </div>                      
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/dropdowns" class="vsm-link"> <span
                          class="vsm-title">Actividades</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/dropdowns" class="vsm-link"> <span
                          class="vsm-title">Ejercicios</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/dropdowns" class="vsm-link"> <span
                          class="vsm-title">Instituciones</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/dropdowns" class="vsm-link"> <span
                          class="vsm-title">Grados</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/dropdowns" class="vsm-link"> <span
                          class="vsm-title">Liencias</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/dropdowns" class="vsm-link"> <span
                          class="vsm-title">Establecimientos</span></a>
                      </div><div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/dropdowns" class="vsm-link"> <span
                          class="vsm-title">Roles</span></a>
                      </div>                      
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="institionsMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-culture"></i>
                    <span class="vsm-title">Instituciones</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item"><a routerLink="/panda/institutions" routerLinkActive="active-item"
                                               [routerLinkActiveOptions]="{exact: true}" class="vsm-link"><span
                        class="vsm-title">Gestionar instituciones</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="uploadsMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon fa fa-upload"></i>
                    <span class="vsm-title">Cargas</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item"><a routerLink="/panda/uploads" routerLinkActive="active-item"
                                               [routerLinkActiveOptions]="{exact: true}" class="vsm-link"><span
                        class="vsm-title">Carga de estudiantes</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel> -->
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">Profesor</div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="teacherMyInstitutionMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-culture"></i>
                    <span class="vsm-title">Mi institución</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/institution" class="vsm-link"> <span
                          class="vsm-title">Ver mi institución</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="teacherMyClassesMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-id"></i>
                    <span class="vsm-title">Mis clases</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/classrooms" class="vsm-link"> <span
                          class="vsm-title">Ver mis clases</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="teacherMyStatisticsMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-graph1"></i>
                    <span class="vsm-title">Estadísticas</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/statistics" class="vsm-link"> <span
                          class="vsm-title">Estadísticas por clase</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <!-- <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">Estudiante</div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="studentMyProgress">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-display1"></i>
                    <span class="vsm-title">Mi progreso</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/widgets/chart-boxes-3" class="vsm-link">
                          <span class="vsm-title">Variation 3</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel> -->
            </ngb-accordion>
            <!-- <div class="vsm-header">Mi Perfil</div>
            <div class="vsm-item">
              <a routerLink="/charts/chartjs" routerLinkActive="active-item" class="vsm-link">
                <i class="vsm-icon pe-7s-user"></i>
                <span class="vsm-title">Mis datos</span>
              </a>
            </div> -->
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>
