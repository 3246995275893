<app-page-title-without-buttons [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title-without-buttons>

<div class="mb-2">
    <app-search-box (searchEvent)="onSearch($event)"></app-search-box>
    <div class="col-md-4 pl-0" >
        <table class="mb-0 table table-bordered" style="background-color: white;">
          <tbody>
            <tr>
              <th scope="row" style="text-align: left;"><fa-icon [icon]="usersIcon" class="mr-2"></fa-icon>Número de cargas:</th>
              <td style="text-align: center;"> 
                2 Carga(s)
              </td>
            </tr>
          </tbody>
        </table>
    </div>
</div>

<div class="main-card mb-3 card">
    <div class="card-header text-center">      
      <h5 class="card-title">CARGAS</h5>
    </div>
    <div class="table-responsive">
      <table class="align-middle mb-0 table table-borderless table-striped table-hover">
        <thead>
        <tr>
          <th class="text-center">#</th>
          <th class="text-center">Fecha</th>
          <th class="text-center">Registros creados</th>
          <th class="text-center">Registros no creados</th>
          <th class="text-center">Descripción</th>
          <th class="text-center">Estado</th>
          <th class="text-center">Acciones</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="text-center text-muted">#345</td>
          <td class="text-center text-muted">2021-11-02</td>
          <td class="text-center">500</td>
          <td class="text-center">0</td>
          <td class="text-center">Carga de estudiantes</td>
          <td class="text-center">Terminado</td>
          <td class="text-center">
            <button class="mr-1 btn btn-danger btn-sm" (click)="openDeleteModal(content6)">
                <fa-icon [icon]="minusIcon" class="mr-2"></fa-icon>
                Borrar
            </button>
          </td>
        </tr>
        <tr>
            <td class="text-center text-muted">#345</td>
            <td class="text-center text-muted">2021-11-02</td>
            <td class="text-center">0</td>
            <td class="text-center">0</td>
            <td class="text-center">Carga de estudiantes</td>
            <td class="text-center">Pendiente</td>
            <td class="text-center">
              <button class="mr-1 btn btn-danger btn-sm" (click)="openDeleteModal(content6)">
                  <fa-icon [icon]="minusIcon" class="mr-2"></fa-icon>
                  Borrar
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>    
    <div class="d-block card-footer">
        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="70" [maxSize]="3" [(page)]="page" [boundaryLinks]="true"></ngb-pagination>
    </div>
  </div>

  <!-- Modal -->
<div class="card-body">
    <ng-template #content6 let-c="close" let-d="dismiss">
      <div class="modal-header">
        <h4 class="modal-title">Borrar carga!</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="text-align: center;">
        <img width="200" src="./assets/images/panda/delete.png" alt="" class="rounded-circle">
        <p>
          ¿Estás seguro que deseas borrar el resultado de la carga?
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')"><i class="fa fa-ban mr-1" aria-hidden="true"></i>Cerrar</button>
        <button type="button" class="btn btn-danger"><fa-icon [icon]="minusIcon" class="mr-2"></fa-icon>Si, Borrar</button>
      </div>
    </ng-template>
  </div>