<div class="chart">
  <canvas baseChart
          [data]="pieChartData"
          [labels]="pieChartLabels"
          [chartType]="pieChartType"
          [options]="pieChartOptions"
          [plugins]="pieChartPlugins"
          [colors]="pieChartColors"
          [legend]="pieChartLegend">
  </canvas>
</div>
<div class="divider mb-12"></div>
<div class="text-center mb-12">
</div>
