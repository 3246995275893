import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { InstitutionModel } from '../Models/institution.model';
import { ResponseModel_old } from '../Models/response.old.model';

@Injectable({
  providedIn: 'root'
})
export class InstitutionService {
  
  url: string = environment.apiURL;
  token: string = localStorage.getItem("token");
  headers= new HttpHeaders()
  .set('Content-Type', 'application/vnd.api+json')
  .set('Accept', 'application/vnd.api+json')
  .set('Access-Control-Allow-Origin', '*')  
  .set('Authorization', 'Bearer ' + this.token);
  params= new HttpParams()
  .set('page', '0')
  .set('size', '30');

  constructor(private http : HttpClient) { }


  public getInstitutions(): Observable<ResponseModel_old> {
    const url= this.url + "/institutions?page=0&size=30"
    return this.http.get<ResponseModel_old>(url, {'headers': this.headers});
  }

  public getInstitution(id: number): Observable<ResponseModel_old> {
    const url= this.url + "/institutions/" + id
    return this.http.get<ResponseModel_old>(url, {'headers': this.headers});
  }

  public createInstitution(institution: InstitutionModel): Observable<ResponseModel_old> {
    const url= this.url + "/institutions"
    return this.http.put<ResponseModel_old>(url, institution, {'headers': this.headers});
  }

  public deleteInstitution(institutionId: number): Observable<ResponseModel_old> {
    const url= this.url + "/institutions/" + institutionId
    return this.http.delete<ResponseModel_old>(url, {'headers': this.headers});
  }

  public updateInstitution(institution: InstitutionModel): Observable<ResponseModel_old> {
    const url= this.url + "/institutions"
    return this.http.post<ResponseModel_old>(url, institution, {'headers': this.headers});
  }

  public getInstitutionClassRooms(institutionId: number): Observable<ResponseModel_old> {
    const url= this.url + "/institutions/" + institutionId + "/classrooms/all?page=0&size=30"
    return this.http.get<ResponseModel_old>(url, {'headers': this.headers});
  }

  public getInstitutionClassRoomsByTeacher(teacherId: number): Observable<ResponseModel_old> {
    const url= this.url + "/institutions/all/classrooms/all/teachers/" +teacherId+ "?page=0&size=30"
    return this.http.get<ResponseModel_old>(url, {'headers': this.headers});
  }

  public getClassroomCount(institutionId: number): Observable<ResponseModel_old> {
    const url= this.url + "/institutions/" + institutionId + "/classrooms/all/count"
    return this.http.get<ResponseModel_old>(url, {'headers': this.headers});
  }

  public getClassroomCountByGrade(institutionId: number): Observable<ResponseModel_old> {
    const url= this.url + "/institutions/" + institutionId + "/classrooms/all/grade/count"
    return this.http.get<ResponseModel_old>(url, {'headers': this.headers});
  }

  public getClassroomCountByStudent(institutionId: number): Observable<ResponseModel_old> {
    const url= this.url + "/institutions/" + institutionId + "/classrooms/all/roles/Estudiante/count"
    return this.http.get<ResponseModel_old>(url, {'headers': this.headers});
  }

  public getClassroomCountByTeacher(institutionId: number): Observable<ResponseModel_old> {
    const url= this.url + "/institutions/" + institutionId + "/classrooms/all/teachers/count"
    return this.http.get<ResponseModel_old>(url, {'headers': this.headers});
  }

  public getClassroom(institutionId: number, classroomId: number): Observable<ResponseModel_old> {
    const url= this.url + "/institutions/" + institutionId + "/classrooms/" + classroomId
    return this.http.get<ResponseModel_old>(url, {'headers': this.headers});
  }
}
