<app-page-title-with-two-buttons [heading]="heading" [subheading]="subheading" [icon]="icon" [action_button]="action_button" 
[action_button_plus]="action_button_plus" (clickEvent)="onClickFileLoad($event)">

</app-page-title-with-two-buttons>

<!-- Datos institución y busqueda -->
<div class="mb-2">
    <div class="col-md-6 pl-0" >
        <table class="mb-0 table table-bordered" style="background-color: white;">
          <tbody>
            <tr>
              <th scope="row" style="text-align: left;"><fa-icon [icon]="usersIcon" class="mr-2"></fa-icon>Número de estudiantes:</th>
              <td style="text-align: center;"> 
                {{totalStudents}} Estudiante(s)
              </td>
              <td style="text-align: center;">  
                <!-- [(ngModel)]="classroomId"   (change)="changeClassroom($event.target.value)"     -->
                <select class="form-control-sm form-control" [(ngModel)]="classroomId" style="text-align: center" (change)="changeClassroom()">
                  <option>Select classroom</option>
                  <option *ngFor="let classroom of classroomList" value="{{classroom.id}}">{{classroom.grade.description}}</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
</div>

<div class="main-card mb-3 card">
    <div class="card-header text-center">      
      <h5 class="card-title">ESTUDIANTES</h5>
    </div>
    <div class="table-responsive">
      <table class="align-middle mb-0 table table-borderless table-striped table-hover">
        <thead>
        <tr>
          <th class="text-center">#</th>
          <th class="text-center">Nombre</th>
          <th class="text-center">Opciones</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let student of studentList">
          <td class="text-center text-muted">{{student.id}}</td>
          <td class="text-center">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left mr-3">
                </div>
                <div class="widget-content-left flex2">
                  <div class="widget-heading">{{student.attributes.fullName}}</div>
                </div>
              </div>
            </div>
          </td>
          <td class="text-center">
            <button type="button" class="mr-1 btn btn-success btn-sm" routerLink="/classrooms/{{this.classroomDescription}}/students/{{student.id}}/personal-information" [state]="{classDescription:classroomId}">
                <fa-icon [icon]="plusIcon" class="mr-2"></fa-icon>
               Ver info personal
            </button>
            <button type="button" class="mr-1 btn btn-primary btn-sm" routerLink="/classrooms/{{classroomId}}/students/{{student.id}}/game-status">
                <fa-icon [icon]="plusIcon" class="mr-2"></fa-icon>
               Ver estado juego 
            </button>
            <button type="button" class="mr-1 btn btn-success btn-sm" routerLink="/classrooms/{{classroomId}}/students/{{student.id}}/game-progress">
                <fa-icon [icon]="plusIcon" class="mr-2"></fa-icon>
               Ver info x unidad
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>    
    <div class="d-block card-footer">
        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="totalStudents" [maxSize]="3" [(page)]="page" (pageChange)="loadStudentList()"></ngb-pagination>
    </div>
  </div>

<!-- Modal -->
<div class="card-body">
  <ng-template #content6 let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">Borrar estudiante!</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="text-align: center;">
      <img width="200" src="./assets/images/panda/delete.png" alt="" class="rounded-circle">
      <p>
        ¿Estás seguro que deseas borrar el estudiante?
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="c('Close click')"><i class="fa fa-ban mr-1" aria-hidden="true"></i>Cerrar</button>
      <button type="button" class="btn btn-danger"><fa-icon [icon]="minusIcon" class="mr-2"></fa-icon>Si, Borrar</button>
    </div>
  </ng-template>
</div>

<!-- Modal -->
<div class="card-body">
  <ng-template #modal_upload let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">Cargar archivo de estudiantes!</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="text-align: center;">
      <img width="200" src="./assets/images/panda/upload.png" alt="" class="rounded-circle">
      <input type="file" name="" id="">
      <p>
        Selecciona el archivo con el listado de estudiantes
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="c('Close click')"><i class="fa fa-ban mr-1" aria-hidden="true"></i>Cerrar</button>
      <button type="button" class="btn btn-success"><i [icon]="minusIcon" class="fa fa-upload mr-2"></i>Cargar</button>
    </div>
  </ng-template>
</div>