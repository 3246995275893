<div class="h-100 bg-plum-plate bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-8">
      <div class="app-logo-inverse mx-auto mb-3"></div>
      <div class="modal-dialog w-100">
        <div class="modal-content">
          <div class="modal-body">
            <h5 class="modal-title">
              <h4 class="mt-2">
                <div>Bienvenido,</div>
                <span>Crear tu cuenta toma <span class="text-success">pocos segundos</span></span></h4>                
            </h5>
            <small tabindex="-1" id="exampleInputGroup1__BV_description_" class="form-text text-muted">
              <span class="text-danger">Recuerda que tu cuenta 
              quedará habilitada una vez sea revisada por panda</span>
            </small>
            <div class="divider"></div>
            <div id="exampleInputGroup1" role="group" aria-describedby="exampleInputGroup1__BV_description_"
                 class="form-group">
              <div><input id="exampleInput1" type="email" placeholder="Ingresa tu correo..." required="required"
                          aria-required="true" class="form-control"
                          aria-describedby="exampleInputGroup1__BV_description_">
                <small tabindex="-1" id="exampleInputGroup1__BV_description_" class="form-text text-muted">No compartiremos tu correo con nadie.
                </small>
              </div>
            </div>
            <div id="exampleInputGroup12" role="group" class="form-group">
              <div><input id="exampleInput12" type="text" placeholder="Ingresa tu usuario..." required="required"
                          aria-required="true" class="form-control"></div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div id="exampleInputGroup2" role="group" class="form-group">
                  <div><input id="exampleInput2" type="password" placeholder="Ingresa tu contraseña..." required="required"
                              aria-required="true" class="form-control"></div>
                </div>
              </div>
              <div class="col-md-6">
                <div id="exampleInputGroup2" role="group" class="form-group">
                  <div><input id="exampleInput2" type="password" placeholder="Repite tu contraseña..." required="required"
                              aria-required="true" class="form-control"></div>
                </div>
              </div>
            </div>
            <div class="custom-control custom-checkbox"><input id="exampleCheck" type="checkbox" name="check"
                                                               autocomplete="off" class="custom-control-input"
                                                               value="true"><label for="exampleCheck"
                                                                                   class="custom-control-label">
              Acepta nuestros <a [routerLink]="">Terminos y condiciones de PandaApp</a>.
            </label></div>
            <div class="divider"></div>
            <h6 class="mb-0">
              Ya tienes una cuenta?
              <a [routerLink]="['/login']" class="text-primary">Ingresa</a>
              |
              <a [routerLink]="['/forgot-password']" class="text-primary">Recuperar contraseña</a></h6></div>
          <div class="modal-footer d-block text-center">
            <button  type="button"
                    class="btn btn-wide btn-pill btn-shadow btn-hover-shine btn-secondary btn-lg">Crear cuenta
            </button>
          </div>
        </div>
      </div>
      <div class="text-center text-white opacity-8 mt-3">
        Copyright © PandaApp 2022
      </div>
    </div>
  </div>
</div>
