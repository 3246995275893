<app-page-title-without-buttons [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title-without-buttons>

<div class="col-md-6">
<div class="card main-card mb-3">
  <div class="card-header" style="text-align: center;">
    <h5>Institución</h5>
  </div>
  <table class="mb-0 table table-bordered">
    <tbody>
      <tr>
        <th scope="row"><i class="fa fa-university" aria-hidden="true" title="drivers-license"></i> Nombre</th>
        <td style="text-align: center;"> 
          <input [(ngModel)]="institution.name" placeholder="Nombre de la institución" type="text" class="form-control-sm form-control">
        </td>
      </tr>
    <tr>
      <th scope="row"><i class="fa fa-fw" aria-hidden="true" title="drivers-license"></i> Tipo de licencia</th>
      <td style="text-align: center;"> 
        <select class="form-control-sm form-control" [(ngModel)]="institution_license.licence.id">
          <option *ngFor="let license of licenseList" value="{{license.id}}">{{license.description}}</option>
        </select>
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="fa fa-fw" aria-hidden="true" title="calendar"></i> Fecha de inicio</th>
      <td style="text-align: center;">
        <input type="date" class="form-control-sm form-control" [(ngModel)]="institution_license.initialDate">
      </td>
    </tr>
    </tbody>
  </table>
  <div class="card-header" style="text-align: center;">
    <button type="button" class="mr-1 btn btn-success btn-sm" (click)="onCreate()">
      <i class="fa fa-floppy-o" aria-hidden="true"></i>
      Crear
    </button>
    <button class="mr-1 btn btn-danger btn-sm" routerLink="classrooms">
        <i class="fa fa-ban" aria-hidden="true"></i>
        Cancelar
    </button>
  </div>
</div>
</div>