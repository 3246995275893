import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(private router : Router){

  }
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
      if (localStorage.getItem('expiration') != null && 
          localStorage.getItem('role') != null && 
          localStorage.getItem('token') != null &&
          localStorage.getItem('userId') != null ) {
            
            var expiration = localStorage.getItem('expiration')
            var expirationFormated =  expiration.substring(0,10) +" "+  expiration.substring(11,19)
            var expirationDate = new Date(expirationFormated);
            var todayDate = new Date();

            if ((expirationDate.getTime() - todayDate.getTime()) < 0) {
              this.router.navigate(["/"])
              localStorage.clear();
              return false;
            }else{
              return true;
            }        
      }else{
        this.router.navigate(["/"])
        return false;
      }
      
  }
  
}
