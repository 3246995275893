<app-page-title2 [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title2>
<div class="row">
  <div class="col-md-6">
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Controls Types</h5>
        <form>
          <div class="position-relative form-group"><label for="exampleEmail">Email</label><input name="email"
                                                                                                  id="exampleEmail"
                                                                                                  placeholder="with a placeholder"
                                                                                                  type="email"
                                                                                                  class="form-control">
          </div>
          <div class="position-relative form-group"><label for="examplePassword">Password</label><input
                  name="password" id="examplePassword" placeholder="password placeholder" type="password"
                  class="form-control"></div>
          <div class="position-relative form-group"><label for="exampleSelect">Select</label><select
                  name="select" id="exampleSelect" class="form-control">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select></div>
          <div class="position-relative form-group"><label for="exampleSelectMulti">Select
            Multiple</label><select multiple="multiple" name="selectMulti" id="exampleSelectMulti"
                                    class="form-control">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select></div>
          <div class="position-relative form-group"><label for="exampleText">Text Area</label><textarea
                  name="text" id="exampleText" class="form-control"></textarea></div>
          <div class="position-relative form-group"><label for="exampleFile">File</label><input name="file"
                                                                                                id="exampleFile"
                                                                                                type="file"
                                                                                                class="form-control-file">
            <small class="form-text text-muted">This is some placeholder block-level help text for the above
              input. It's a bit lighter and easily wraps to a new line.
            </small>
          </div>
          <button class="mt-1 btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Sizing</h5>
        <form><input placeholder="lg" type="text" class="mb-2 form-control-lg form-control"><input
                placeholder="default" type="text" class="mb-2 form-control"><input placeholder="sm" type="text"
                                                                                   class="mb-2 form-control-sm form-control">
          <div class="divider"></div>
          <select class="mb-2 form-control-lg form-control">
            <option>Large Select</option>
          </select><select class="mb-2 form-control">
            <option>Default Select</option>
          </select><select class="form-control-sm form-control">
            <option>Small Select</option>
          </select></form>
      </div>
    </div>
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Checkboxes &amp; Radios</h5>
        <form>
          <fieldset class="position-relative form-group">
            <div class="position-relative form-check"><label class="form-check-label"><input name="radio1"
                                                                                             type="radio"
                                                                                             class="form-check-input">
              Option one is this and that—be sure to include why it's great</label></div>
            <div class="position-relative form-check"><label class="form-check-label"><input name="radio1"
                                                                                             type="radio"
                                                                                             class="form-check-input">
              Option two can be something else and selecting it will deselect option one</label></div>
            <div class="position-relative form-check disabled"><label class="form-check-label"><input
                    name="radio1" disabled="disabled" type="radio" class="form-check-input"> Option three is disabled</label>
            </div>
          </fieldset>
          <div class="position-relative form-check"><label class="form-check-label"><input type="checkbox"
                                                                                           class="form-check-input">
            Check me out</label></div>
        </form>
      </div>
    </div>
  </div>
</div>
<form>
  <div class="row">
    <div class="col-md-6">
      <div class="main-card mb-3 card">
        <div class="card-body"><h5 class="card-title">Checkboxes</h5>
          <div class="position-relative form-group">
            <div>
              <div class="custom-checkbox custom-control"><input type="checkbox" id="exampleCustomCheckbox"
                                                                 class="custom-control-input"><label
                      for="exampleCustomCheckbox" class="custom-control-label">Check this custom checkbox</label>
              </div>
              <div class="custom-checkbox custom-control"><input type="checkbox" id="exampleCustomCheckbox2"
                                                                 class="custom-control-input"><label
                      for="exampleCustomCheckbox2" class="custom-control-label">Or this one</label></div>
              <div class="custom-checkbox custom-control"><input type="checkbox" id="exampleCustomCheckbox3"
                                                                 disabled="disabled"
                                                                 class="custom-control-input"><label
                      for="exampleCustomCheckbox3" class="custom-control-label">But not this disabled one</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-card mb-3 card">
        <div class="card-body"><h5 class="card-title">Inline</h5>
          <div class="position-relative form-group">
            <div>
              <div class="custom-checkbox custom-control custom-control-inline"><input type="checkbox"
                                                                                       id="exampleCustomInline"
                                                                                       class="custom-control-input"><label
                      for="exampleCustomInline" class="custom-control-label">An inline custom input</label></div>
              <div class="custom-checkbox custom-control custom-control-inline"><input type="checkbox"
                                                                                       id="exampleCustomInline2"
                                                                                       class="custom-control-input"><label
                      for="exampleCustomInline2" class="custom-control-label">and another one</label></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="main-card mb-3 card">
        <div class="card-body"><h5 class="card-title">Radios</h5>
          <div class="position-relative form-group">
            <div>
              <div class="custom-radio custom-control"><input type="radio" id="exampleCustomRadio"
                                                              name="customRadio"
                                                              class="custom-control-input"><label
                      for="exampleCustomRadio" class="custom-control-label">Select this custom radio</label></div>
              <div class="custom-radio custom-control"><input type="radio" id="exampleCustomRadio2"
                                                              name="customRadio"
                                                              class="custom-control-input"><label
                      for="exampleCustomRadio2" class="custom-control-label">Or this one</label></div>
              <div class="custom-radio custom-control"><input type="radio" id="exampleCustomRadio3"
                                                              disabled="disabled"
                                                              class="custom-control-input"><label
                      for="exampleCustomRadio3" class="custom-control-label">But not this disabled one</label></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-card mb-3 card">
        <div class="card-body"><h5 class="card-title">Form Select</h5>
          <div class="row">
            <div class="col-md-6">
              <div class="position-relative form-group"><label for="exampleCustomSelect">Custom
                Select</label><select type="select" id="exampleCustomSelect" name="customSelect"
                                      class="custom-select">
                <option value="">Select</option>
                <option>Value 1</option>
                <option>Value 2</option>
                <option>Value 3</option>
                <option>Value 4</option>
                <option>Value 5</option>
              </select></div>
              <div class="position-relative form-group"><label for="exampleCustomMutlipleSelect">Custom Multiple
                Select</label><select multiple="multiple" type="select" id="exampleCustomMutlipleSelect"
                                      name="customSelect" class="custom-select">
                <option value="">Select</option>
                <option>Value 1</option>
                <option>Value 2</option>
                <option>Value 3</option>
                <option>Value 4</option>
                <option>Value 5</option>
              </select></div>
            </div>
            <div class="col-md-6">
              <div class="position-relative form-group"><label for="exampleCustomSelectDisabled">Custom Select
                Disabled</label><select type="select" id="exampleCustomSelectDisabled" name="customSelect"
                                        disabled="disabled" class="custom-select">
                <option value="">Select</option>
                <option>Value 1</option>
                <option>Value 2</option>
                <option>Value 3</option>
                <option>Value 4</option>
                <option>Value 5</option>
              </select></div>
              <div class="position-relative form-group"><label for="exampleCustomMutlipleSelectDisabled">Custom
                Multiple Select Disabled</label><select multiple="multiple" type="select"
                                                        id="exampleCustomMutlipleSelectDisabled"
                                                        name="customSelect" disabled="disabled"
                                                        class="custom-select">
                <option value="">Select</option>
                <option>Value 1</option>
                <option>Value 2</option>
                <option>Value 3</option>
                <option>Value 4</option>
                <option>Value 5</option>
              </select></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="row">
  <div class="col-md-6">
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Input Groups</h5>
        <div>
          <div class="input-group">
            <div class="input-group-prepend"><span class="input-group-text">@</span></div>
            <input placeholder="username" type="text" class="form-control"></div>
          <br>
          <div class="input-group">
            <div class="input-group-prepend"><span class="input-group-text"><input
                    aria-label="Checkbox for following text input" type="checkbox"></span></div>
            <input placeholder="Check it out" type="text" class="form-control"></div>
          <br>
          <div class="input-group"><input placeholder="username" type="text" class="form-control">
            <div class="input-group-append"><span class="input-group-text">@example.com</span></div>
          </div>
          <br>
          <div class="input-group">
            <div class="input-group-prepend"><span class="input-group-text">$</span><span
                    class="input-group-text">$</span></div>
            <input placeholder="Dolla dolla billz yo!" type="text" class="form-control">
            <div class="input-group-append"><span class="input-group-text">$</span><span class="input-group-text">$</span>
            </div>
          </div>
          <br>
          <div class="input-group">
            <div class="input-group-prepend"><span class="input-group-text">$</span></div>
            <input placeholder="Amount" step="1" type="number" class="form-control">
            <div class="input-group-append"><span class="input-group-text">.00</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Input Group Button Dropdown</h5>
        <div role="group" class="input-group">
          <div class="input-group-prepend">
            <button type="button" class="btn btn-info">
              Button
            </button>
          </div>
          <input type="text" class="form-control">
          <div class="input-group-append">
            <button type="button" class="btn btn-outline-secondary">
              Button
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Input Group Button Shorthand</h5>
        <div>
          <div class="input-group">
            <div class="input-group-prepend">
              <button class="btn btn-secondary">To the Left!</button>
            </div>
            <input type="text" class="form-control"></div>
          <br>
          <div class="input-group"><input type="text" class="form-control">
            <div class="input-group-append">
              <button class="btn btn-secondary">To the Right!</button>
            </div>
          </div>
          <br>
          <div class="input-group">
            <div class="input-group-prepend">
              <button class="btn btn-danger">To the Left!</button>
            </div>
            <input placeholder="and..." type="text" class="form-control">
            <div class="input-group-append">
              <button class="btn btn-success">To the Right!</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Input Group Sizing</h5>
        <div>
          <div class="input-group input-group-lg">
            <div class="input-group-prepend"><span class="input-group-text">@lg</span></div>
            <input type="text" class="form-control"></div>
          <br>
          <div class="input-group">
            <div class="input-group-prepend"><span class="input-group-text">@normal</span></div>
            <input type="text" class="form-control"></div>
          <br>
          <div class="input-group input-group-sm">
            <div class="input-group-prepend"><span class="input-group-text">@sm</span></div>
            <input type="text" class="form-control"></div>
        </div>
      </div>
    </div>
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Input Group Addon</h5>
        <div>
          <div class="input-group">
            <div class="input-group-prepend"><span class="input-group-text">To the Left!</span></div>
            <input type="text" class="form-control"></div>
          <br>
          <div class="input-group"><input type="text" class="form-control">
            <div class="input-group-append"><span class="input-group-text">To the Right!</span></div>
          </div>
          <br>
          <div class="input-group">
            <div class="input-group-prepend"><span class="input-group-text">To the Left!</span></div>
            <input placeholder="and..." type="text" class="form-control">
            <div class="input-group-append"><span class="input-group-text">To the Right!</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">Input Group Button</h5>
        <div>
          <div class="input-group">
            <div class="input-group-prepend">
              <button class="btn btn-secondary">I'm a button</button>
            </div>
            <input type="text" class="form-control"></div>
          <br>
          <div role="group" class="input-group">
            <input type="text" class="form-control">
            <div class="input-group-append">
              <button type="button" class="btn btn-secondary">
                Button
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
