import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
})
export class SearchBoxComponent implements OnInit {

  //Variables
  public isActive: any = false;
  textToSearch : String
  @Output() searchEvent = new EventEmitter<String>();

  onSearch(){    
    if (this.isActive != false) {
      this.searchEvent.emit(this.textToSearch)
    }
    this.isActive = true    
  }

  constructor() { }

  ngOnInit() {
  }

}
