import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClassroomModel } from 'src/app/Models/classroom.model';
import { UserModel } from 'src/app/Models/NewModels/user.model';
import { InstitutionService } from 'src/app/Services/institution.service';
import { StudentService } from 'src/app/Services/student.service';

@Component({
  selector: 'app-student-personal-info',
  templateUrl: './student-personal-info.component.html',
  styles: [
  ]
})
export class StudentPersonalInfoComponent implements OnInit {

  heading = 'Información sobre el estudiante'
  subheading = 'Aquí podrás encontrar la información personal del estudiante que seleccionaste.'
  icon = 'fa fa-user icon-gradient bg-tempting-azure'
  page = 3
  student = {} as UserModel
  classroomList = {} as ClassroomModel []
  classroomDescription : string = "Select classroom"
  studentId : number
  classroomId : number

  
  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private studentService: StudentService,
    private institutionService: InstitutionService) { }

  ngOnInit(): void {
    this.loadPersonalInformation()
  }

  loadPersonalInformation(){
    this.studentId = this.route.snapshot.params['studentId']
    this.classroomDescription = this.route.snapshot.params['classroomId']
    this.studentService.getStudentPersonalInformation(this.studentId).subscribe(response => {
      if(response.errors){
        console.log("Error")
      }
      if(!response.errors){
        this.student = response.data
        this.student.attributes.birth = this.student.attributes.birth.substring(0,10)
      }
    })
  }

}
