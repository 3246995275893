import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faStar, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-page-title-with-two-buttons',
  templateUrl: './page-title-with-two-buttons.component.html'
})
export class PageTitleWithTwoButtonsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  faStar = faStar;
  faPlus = faPlus;

  @Input() heading;
  @Input() subheading;
  @Input() action_button;
  @Input() action_button_plus;
  @Input() icon;
  @Output() clickEvent = new EventEmitter<String>();

  onClick(){
    this.clickEvent.emit("")
  }

}
