import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { LoginModel, LoginResponse, LoginToken } from 'src/app/Models/login.model';
import { AuthService } from 'src/app/Services/auth.service';
import { ResponseModel } from 'src/app/Models/response.model';
import jwtDecode from 'jwt-decode';

@Component({
  selector: 'app-login-boxed',
  templateUrl: './login-boxed.component.html',
  styles: []
})
export class LoginBoxedComponent implements OnInit {

  //Locals
  isError : Boolean = false
  jwtToken : String
  loginResponse : LoginResponse
  //Models
  response : ResponseModel
  //Forms
  loginForm = new FormGroup({
    username : new FormControl('', Validators.required),
    password : new FormControl('', Validators.required),
    origin : new FormControl('FrontEnd')
  })

   constructor(private router : Router, private authService : AuthService) {  }

  ngOnInit() {
    
  }

  onSubmit(form : LoginModel) : void {    
    
    this.authService.getAuthenticationToken(form).subscribe( response => {
      
      if(response.errors){
        this.isError = true
      }

      if(!response.errors && response.data){
        this.isError = false
        this.loginResponse = response.data.attributes
        const decodeToken : LoginToken = jwtDecode(this.loginResponse.token)
        
        var roles : string = ""
        decodeToken.authorities.forEach(element => {
          roles = roles.concat(element.authority + ",")
        });


        localStorage.setItem("token",this.loginResponse.token)
        localStorage.setItem("expiration",this.loginResponse.expirationTime)
        localStorage.setItem("role",roles)
        localStorage.setItem("userId",response.data.id)

        this.authService.getUserInfo(this.loginForm.get("username").value).subscribe(response => {
          if(response.errors){
          }
          if(!response.errors){
            localStorage.setItem("userId",response.data.id)
            this.router.navigate(["/classrooms"])
          }
        })

        // this.router.navigate(["/panda/institutions"])
        // this.router.navigate(["/classrooms"])

      }
    })  
  }

}

