import { Component, OnInit } from '@angular/core';
import { faInfo, faSearch, faTrash, faUsers } from '@fortawesome/free-solid-svg-icons';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { InstitutionModel } from 'src/app/Models/institution.model';
import { InstitutionService } from 'src/app/Services/institution.service';
import { ResponseModel_old } from 'src/app/Models/response.old.model';
import { LicenseService } from 'src/app/Services/license.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-institution-list',
  templateUrl: './institution-list.component.html',
  styles: [],
  providers: [InstitutionService]
})
export class InstitutionListComponent implements OnInit {

  heading = 'Panel administrativo - Instituciones'
  subheading = 'Aquí podrás administrar las instituciones que tienen acceso a panda.'
  action_button = 'Crear Institución'
  icon = 'pe-7s-culture icon-gradient bg-tempting-azure'
  plusIcon = faInfo
  minusIcon = faTrash
  searchIcon = faSearch
  usersIcon = faUsers
  page : number = 1;
  closeResult: string;
  institutionsList : InstitutionModel []
  institution : InstitutionModel
  institutionsCount : number

  
  constructor(private modalService: NgbModal, 
              private institutionService: InstitutionService, 
              private pagination : NgxPaginationModule, 
              private route: Router,
              private licenseService: LicenseService) { }

  ngOnInit(): void {
    this.getInstitutions()
  }

  onSearch(text : String){
  }

  onClick(text: String){    
    this.route.navigate(["/panda/institutions/create"])
  }

  getInstitutions(){
    this.institutionService.getInstitutions().subscribe(response => {          
      if(!response.status){
      }

      if(response.status){
        this.institutionsList = response.response
        this.institutionsCount = this.institutionsList.length
      }
    })
  }

  deleteInstitution(){
    this.licenseService.getInstitutionLicense(this.institution.id).subscribe(response => {
      if(!response.status){
      }
      if(response.status){
        this.licenseService.deleteInstitutionLicense(response.response[0].id).subscribe(response => {
          if(!response.status){
          }
          if(response.status){            
            this.institutionService.deleteInstitution(this.institution.id).subscribe(response => {          
              if(!response.status){
              }
              if(response.status){
                this.getInstitutions()
                this.modalService.dismissAll()
              }
            })
          }
        })      
      }
    })
  }

  open(content, institution: InstitutionModel) {
    this.institution = institution
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
