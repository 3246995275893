<app-page-title2 [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title2>
<div class="col-md-8 mx-auto">
  <div class="card">
    <div class="card-header">
      Basic
    </div>
    <div class="card-body">
      <ngb-carousel *ngIf="images">
        <ng-template ngbSlide>
          <img [src]="images[0]" width="100%" alt="Random first slide">
          <div class="carousel-caption">
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <img [src]="images[1]" width="100%" alt="Random second slide">
          <div class="carousel-caption">
            <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
        </ng-template>
        <ng-template ngbSlide>
          <img [src]="images[2]" width="100%" alt="Random third slide">
          <div class="carousel-caption">
            <h3>Third slide label</h3>
            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
          </div>
        </ng-template>
      </ngb-carousel>

    </div>
  </div>
</div>
