<app-page-title-without-buttons [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title-without-buttons>


<div class="mb-2">
    <div class="col-md-4 pl-0" >
        <table class="mb-0 table table-bordered" style="background-color: white;">
        <tbody>
            <tr>
            <th scope="row" style="text-align: left;"><i class=" mr-1 fa fa-info-circle" aria-hidden="true"></i>Unidad: </th>
            <td style="text-align: center;"> 
                <select class="form-control-sm form-control" [(ngModel)]="unitNumber" (change)="changeUnit($event.target.value)">
                    <option>Selecciona la unidad...</option>
                    <option *ngFor="let unit of units" value="{{unit}}">Unidad {{unit}}</option>
                </select>
            </td>
            </tr>
        </tbody>
        </table>
    </div>
</div>

<div style="display: grid; grid-auto-flow: column ; grid-auto-columns: 50%; gap: 50px;">
    <div>
        <div class="card main-card mb-2">
            <div class="card-header" style="text-align: center;">
                <h5>General</h5>
            </div>
            <table class="mb-0 table table-bordered">
                <tbody>
                <tr>
                    <th scope="row"><i class="fa fa-clock-o mr-1" aria-hidden="true"></i>Tiempo total (minutos)</th>
                    <td style="text-align: center;">{{unitTime}} min</td>
                </tr>
                </tbody>
            </table>
        </div>



        <div class="card main-card">
        <div class="card-header" style="text-align: center;">
            <h5>Evaluaciones</h5>
        </div>
        <table class="mb-0 table table-bordered">
            <tbody>
            <tr *ngFor="let evaluation of evaluations; let i=index">
                <th scope="row"><i class="fa fa-percent mr-1" aria-hidden="true"></i>Evaluación {{i+1}}</th>
                <td style="text-align: center;"> 
                    {{evaluation.attributes.score}}%
                </td>
            </tr>
            <tr *ngFor="let fakeEvaluation of fakeEvaluations; let i=index">
                <th scope="row"><i class="fa fa-percent mr-1" aria-hidden="true"></i>Evaluación {{fakeEvaluation + 1}}</th>
                <td style="text-align: center;"> 
                    0%
                </td>
            </tr>
            </tbody>
        </table>
        </div>
        <div class="" style="text-align: center;">
            <button class="mr-1 mt-3 btn btn-primary btn-sm" routerLink="/classrooms/{{classroomId}}">
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                Volver
            </button>
        </div>
    </div>

    <div class="card main-card mb-3 mr-5">
    <div class="card-header" style="text-align: center;">
        <h5>Práctica libre y misiones</h5>
    </div>
    <table class="mb-0 table table-bordered" style="text-align: center;">
        <thead >
            <th>Misión</th>
            <th>Tiempo total</th>
            <th>N° errores</th>
        </thead>
        <tbody>
        <tr *ngFor="let mission of missions; let i=index">
            <td style="text-align: center;" *ngIf="i == 0"> Práctica </td>
            <td style="text-align: center;" *ngIf="i != 0"> Misión {{i}} </td>
            <td style="text-align: center;"> {{mission.attributes.time > 0 ? (mission.attributes.time/60 | number: '1.0-2') : mission.attributes.time}} min </td>
            <td style="text-align: center;"> {{mission.attributes.errorsNumber}} </td>
        </tr>        
        <tr *ngFor="let fakeMission of fakeMissions let i=index">
            <td style="text-align: center;" *ngIf="fakeMission == 0"> Práctica </td>
            <td style="text-align: center;" *ngIf="fakeMission != 0"> Misión {{fakeMission}} </td>
            <td style="text-align: center;"> 0 </td>
            <td style="text-align: center;"> 0 </td>
        </tr>  
        </tbody>
    </table>
    </div>
</div>