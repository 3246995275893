<app-page-title-without-buttons [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title-without-buttons>

<div class="col-md-6">
<div class="card main-card mb-3">
  <div class="card-header" style="text-align: center;">
    <h5><span contenteditable="true" (blur)="changeInstitutionName($event)">{{institution.name}}</span></h5>
  </div>
  <table class="mb-0 table table-bordered">
    <tbody>
    <tr>
      <th scope="row"><i class="fa fa-fw" aria-hidden="true" title="drivers-license"></i> Tipo de licencia</th>
      <td style="text-align: center;">         
        {{license.description}}
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="fa fa-fw" aria-hidden="true" title="calendar"></i> Fecha de inicio</th>
      <td style="text-align: center;">
        {{institution_license.initialDate}}
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="fa fa-fw" aria-hidden="true" title="calendar"></i>Fecha de finalización </th>
      <td style="text-align: center;">
        {{institution_license.endDate}}
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="fa fa-fw" aria-hidden="true" title="sitemap"></i> Numero de grados</th>
      <td style="text-align: center;">
        {{gradesNumber}}
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="fa fa-fw" aria-hidden="true" title="object-group"></i> Numero de salones</th>
      <td style="text-align: center;">
        {{classroomsNumber}}
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="fa fa-fw" aria-hidden="true" title="users"></i> Numero de estudiantes habilitados</th>
      <td style="text-align: center;">
        {{studentsNumber}}
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="fa fa-fw" aria-hidden="true" title="users"></i> Numero de docentes habilitados</th>
      <td style="text-align: center;">
        {{teachersNumber}}
      </td>
    </tr>
    </tbody>
  </table>
  <div class="card-header" style="text-align: center;">
    <button class="mr-1 btn btn-primary btn-sm" routerLink="/classrooms">
        <i class="fa fa-arrow-left" aria-hidden="true"></i>
        Volver
    </button>
  </div>
</div>
</div>