import { Component, OnInit } from '@angular/core';
import { faInfo, faSearch, faTrash, faUsers } from '@fortawesome/free-solid-svg-icons';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-upload-list',
  templateUrl: './upload-list.component.html'
})
export class UploadListComponent implements OnInit {

  heading = 'Panel administrativo - Cargas'
  subheading = 'Aquí podrás ver las cargas realizadas mediante archivos.'
  icon = 'fa fa-upload icon-gradient bg-tempting-azure'
  plusIcon = faInfo
  minusIcon = faTrash
  searchIcon = faSearch
  usersIcon = faUsers
  page = 3
  closeResult: string;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  onSearch(text : String){

  }
  
  openDeleteModal(content) {
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
