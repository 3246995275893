<!-- <div class="h-100 bg-plum-plate bg-animation"> -->
<div class="h-100 bg-white bg-gradient bg-animation ">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-8">
      <div class="app-logo-inverse mx-auto mb-3"></div>
      <div class="modal-dialog w-100 mx-auto">
        
        <!-- Login Form -->
        <form [formGroup] = "loginForm" (ngSubmit)="onSubmit(loginForm.value)">
          <div class="modal-content">
            <div class="modal-body">
              <div class="h5 modal-title text-center">
                <h4 class="mt-2">
                  <div>Hola!</div>
                  <span>Ingresa a tu cuenta</span></h4>
              </div>
              <div id="exampleInputGroup1" role="group" aria-describedby="exampleInputGroup1__BV_description_"
                  class="form-group">
                <div><input id="username" type="email" placeholder="Ingresa tu nombre de usuario..." required="required"
                            aria-required="true" class="form-control"
                            aria-describedby="exampleInputGroup1__BV_description_"
                            formControlName="username">
                  <small tabindex="-1" id="exampleInputGroup1__BV_description_" class="form-text text-muted">No compartiremos tu usuario y/o correo con nadie.
                  </small>
                </div>
              </div>
              <div id="exampleInputGroup2" role="group" class="form-group">
                <div><input id="password" type="password" placeholder="Ingresa tu contraseña..." required="required"
                            aria-required="true" class="form-control"
                            formControlName="password"></div>
              </div>
              <!-- <div class="custom-control custom-checkbox"><input id="exampleCheck" type="checkbox" name="check"
                                                                autocomplete="off" class="custom-control-input"
                                                                value="true"><label for="exampleCheck"
                                                                                    class="custom-control-label">
                Mantenerme logueado
              </label></div> -->
              <div *ngIf="isError" class="alert alert-danger" role="alert">
                <small tabindex="-1" style="color: red;" class="form-text">
                  Parece que tu usuario o contraseña no son validos, intenta de nuevo!
                </small>
              </div>
              <div class="divider"></div>
              <!-- <h6 class="mb-0">
                No tienes cuenta?
                <a [routerLink]="['/register']" class="text-primary">Crear cuenta ahora</a></h6> -->
              </div>
            <div class="modal-footer clearfix">
              <!-- <div class="float-left"><a [routerLink]="['/forgot-password']" class="btn-lg btn btn-link">Recuperar contraseña</a></div> -->
              <div class="float-right">
                <button type="submit" class="btn btn-primary btn-lg">Ingresar</button>
              </div>
            </div>
          </div>
        </form>  


      </div>
      <div class="text-center text-black opacity-8 mt-3">
        Copyright © PandaApp 2022
      </div>
    </div>
  </div>
</div>
