import {Component, Input} from '@angular/core';
import { faStar, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-page-title-without-buttons',
  templateUrl: './page-title-without-buttons.component.html',
  styles: [
  ]
})
export class PageTitleWithoutButtonsComponent {

  faStar = faStar;
  faPlus = faPlus;

  @Input() heading;
  @Input() subheading;
  @Input() action_button;
  @Input() icon;

}
