<app-page-title2 [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title2>

<div class="row">
  <div class="col-md-6">
    <h6 class="mb-3">
      Basic
    </h6>
    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
      <ngb-panel>
        <ng-template ngbPanelTitle>
          <span class="card-header--title">Simple</span>
        </ng-template>
        <ng-template ngbPanelContent>
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon
          officia
          aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
          tempor,
          sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh
          helvetica,
          craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.
          Leggings occaecat
          craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
          labore sustainable VHS.
        </ng-template>
      </ngb-panel>
      <ngb-panel>
        <ng-template ngbPanelTitle>
          <span class="card-header--title">&#9733;
		    <b>Fancy</b> title &#9733;
          </span>
        </ng-template>
        <ng-template ngbPanelContent>
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon
          officia aute,
          non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
          sunt aliqua
          put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
          craft beer labore
          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft
          beer farm-to-table,
          raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
        </ng-template>
      </ngb-panel>
      <ngb-panel [disabled]="true">
        <ng-template ngbPanelTitle>
          <span class="card-header--title">Disabled</span>
        </ng-template>
        <ng-template ngbPanelContent>
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
          3 wolf moon officia aute,
          non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf
          moon tempor, sunt aliqua put a bird
          on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
          craft beer labore wes anderson cred nesciunt
          sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table,
          raw denim aesthetic synth nesciunt you
          probably haven't heard of them accusamus labore sustainable VHS.
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
    <h6 class="mb-3 mt-3">
      One panel at a time
    </h6>
    <ngb-accordion [closeOthers]="true" activeIds="static-1">
      <ngb-panel id="static-1">
        <ng-template ngbPanelTitle>
          <span class="card-header--title">Simple</span>
        </ng-template>
        <ng-template ngbPanelContent>
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon
          officia aute,
          non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
          sunt aliqua
          put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
          craft beer labore
          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft
          beer farm-to-table,
          raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
        </ng-template>
      </ngb-panel>
      <ngb-panel id="static-2">
        <ng-template ngbPanelTitle>
               <span>&#9733;
              <b>Fancy</b> title &#9733;
              </span>
        </ng-template>
        <ng-template ngbPanelContent>
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon
          officia aute, non
          cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
          aliqua put a bird
          on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer
          labore wes anderson cred
          nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
          farm-to-table, raw denim
          aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
        </ng-template>
      </ngb-panel>
      <ngb-panel id="static-3" [disabled]="true">
        <ng-template ngbPanelTitle>
          <span class="card-header--title">Disabled</span>
        </ng-template>
        <ng-template ngbPanelContent>
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon
          officia aute, non
          cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
          aliqua put a bird
          on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer
          labore wes anderson
          cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
          farm-to-table, raw denim aesthetic
          synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
  <div class="col-md-6">
    <h6 class="mb-3">
      Toggle Panels
    </h6>
    <ngb-accordion #acc11="ngbAccordion">
      <ngb-panel id="toggle-11">
        <ng-template ngbPanelTitle>
          <span class="card-header--title">First panel</span>
        </ng-template>
        <ng-template ngbPanelContent>
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon
          officia aute,
          non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
          sunt aliqua
          put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
          craft beer
          labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
          occaecat craft
          beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore
          sustainable VHS.
        </ng-template>
      </ngb-panel>
      <ngb-panel id="toggle-22">
        <ng-template ngbPanelTitle>
          <span class="card-header--title">Second</span>
        </ng-template>
        <ng-template ngbPanelContent>
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon
          officia aute,
          non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
          sunt aliqua
          put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
          craft beer
          labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
          occaecat craft
          beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore
          sustainable VHS.
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
    <div class="divider"></div>
    <div class="text-center">
      <button class="btn btn-primary mr-2" (click)="acc11.toggle('toggle-11')">Toggle first</button>
      <button class="btn btn-info" (click)="acc11.toggle('toggle-22')">Toggle second</button>
    </div>
    <h6 class="mb-3 mt-3">
      Prevent panel toggle
    </h6>
    <ngb-accordion (panelChange)="beforeChange($event)">
      <ngb-panel id="preventchange-1">
        <ng-template ngbPanelTitle>
          <span class="card-header--title">Simple</span>
        </ng-template>
        <ng-template ngbPanelContent>
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon
          officia aute,
          non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
          sunt aliqua
          put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
          craft beer
          labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
          occaecat craft beer
          farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore
          sustainable VHS.
        </ng-template>
      </ngb-panel>
      <ngb-panel id="preventchange-2">
        <ng-template ngbPanelTitle>
          <span class="card-header--title">I can't be toggled...</span>
        </ng-template>
        <ng-template ngbPanelContent>
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon
          officia aute,
          non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
          sunt aliqua
          put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
          craft beer labore
          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft
          beer farm-to-table,
          raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
        </ng-template>
      </ngb-panel>
      <ngb-panel id="preventchange-3">
        <ng-template ngbPanelTitle>
          <span class="card-header--title">I can be opened, but not closed..."</span>
        </ng-template>
        <ng-template ngbPanelContent>
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon
          officia aute,
          non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
          sunt aliqua
          put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
          craft beer labore
          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft
          beer farm-to-table,
          raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
        </ng-template>
      </ngb-panel>
    </ngb-accordion>

    <div class="card mb-3 mt-3">
      <div class="card-header">
        Collapse
      </div>
      <div class="card-body">
        <button type="button"
                class="btn btn-primary"
                (click)="isCollapsed = !isCollapsed"
                [attr.aria-expanded]="!isCollapsed"
                aria-controls="collapseExample">
          Toggle
        </button>

        <div id="collapseExample" class="mt-3" [ngbCollapse]="isCollapsed">
          <div class="card">
            <div class="card-body">
              You can collapse this card by clicking Toggle
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
