import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faInfo, faTrash, faSearch, faUsers } from '@fortawesome/free-solid-svg-icons';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { ClassroomModel } from 'src/app/Models/classroom.model';
import { KeyValModel } from 'src/app/Models/key-val.model';
import { InstitutionService } from 'src/app/Services/institution.service';
import { StudentService } from 'src/app/Services/student.service';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'app-chart-class',
  templateUrl: './chart-class.component.html',
  styles: [
  ]
})
export class ChartClassComponent implements OnInit {

  heading = 'Panel administrativo - Estadísticas'
  subheading = 'Aquí podrás encontrar algúnas estadísticas por clase.'
  action_button = 'Crear Estudiante'
  action_button_plus = 'Cargar archivo de Estudiantes'
  icon = 'fa fa-users icon-gradient bg-tempting-azure'
  plusIcon = faInfo
  minusIcon = faTrash
  searchIcon = faSearch
  usersIcon = faUsers
  userId: number;
  classroomList = [] as ClassroomModel []
  unitTop = [] as Array<KeyValModel>
  CoinTop = [] as Array<KeyValModel>
  classroomId : string = "Select classroom"
  classroomDescription: string;
  
  constructor(private route: ActivatedRoute, private router: Router, private institutionService: InstitutionService, private studentService: StudentService) {     
  }

  ngOnInit(): void {
    this.userId = parseInt(localStorage.getItem("userId"))
    this.loadClassRooms()
  }

  loadClassRooms(){ 

    this.institutionService.getInstitutionClassRoomsByTeacher(this.userId).subscribe(response => {
      if(!response.status){
        console.log("Error")
      }
      if(response.status){
        this.classroomList = response.response
        if (this.classroomList.length > 0) {
          localStorage.setItem("institutionId", this.classroomList[0].institution.id.toString())
        }else{
          localStorage.setItem("institutionId", "0")
        }
        if (typeof this.route.snapshot.params['classroomId'] !== 'undefined') {
          this.classroomId = this.route.snapshot.params['classroomId']
          this.changeClassroom()
        }
      }
    })
  }

  changeClassroom() {
    this.classroomList.forEach(classroom => {
      if (classroom.id.toString() == this.classroomId) {
        this.classroomDescription = classroom.grade.description
        this.loadTimeLine()
        this.loadPie()
        this.loadTopByUnit()
        this.loadTopByCoins()
      }
    });
  }

  loadTopByUnit(){
    this.unitTop = []
    this.studentService.getStudentGameProgressTopByUnit(this.classroomId).subscribe(response => {
      if(!response.status){
        console.log("Error")
      }
      if(response.status){
        var auxObject : Object = response.response
        for (const [key, val] of Object.entries(auxObject)) {
          this.unitTop.push({key,val})
        }
      }
    })
  }  

  loadTopByCoins(){
    this.CoinTop = []
    this.studentService.getStudentGameProgressTopByCoin(this.classroomId).subscribe(response => {
      if(!response.status){
        console.log("Error")
      }
      if(response.status){
        var auxObject : Object = response.response
        for (const [key, val] of Object.entries(auxObject)) {
          this.CoinTop.push({key,val})
        }
      }
    })
  }  

  loadTimeLine(){
    this.studentService.getStudentGameProgressTimeLine(this.classroomId).subscribe(response => {
      if(!response.status){
        console.log("Error")
      }
      if(response.status){
        this.lineChartData[0].data = response.response
      }
    })
  }  

  loadPie(){
    this.studentService.getStudentGameProgressCountByUnit(this.classroomId).subscribe(response => {
      if(!response.status){
        console.log("Error")
      }
      if(response.status){
        var unitObject : Object = response.response

      this.pieChartLabels = [];
      this.pieChartData = [];
      for (const [key, val] of Object.entries(unitObject)) {
        this.pieChartLabels.push(key)
        this.pieChartData.push(val)
      }
        
        
        // pieChartLabels
        // pieChartData: 
      }
    })
  }

  onClickFileLoad(text : String){
  }

  //----------------------------------------------line
  public lineChartData: ChartDataSets[] = [
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Tiempo '}
  ];
  
  public lineChartLabels: Label[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
        },
        {
          id: 'y-axis-1',
          position: 'right',
          gridLines: {
            color: 'rgba(255,0,0,0.3)',
          },
          ticks: {
            fontColor: 'red',
          }
        }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    { // red
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [pluginAnnotations];

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }

  public hideOne() {
    const isHidden = this.chart.isDatasetHidden(1);
    this.chart.hideDataset(1, !isHidden);
  }
  //----------------------------------------------line

  //----------------------------------------------cake
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  public pieChartLabels: Label[] = ["Unidades"];
  public pieChartData: number[] = [0];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
    },
  ];
  //----------------------------------------------cake
}
