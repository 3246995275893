import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgReduxModule} from '@angular-redux/store';
import {NgRedux, DevToolsExtension} from '@angular-redux/store';
import {rootReducer, ArchitectUIState} from './ThemeOptions/store';
import {ConfigActions} from './ThemeOptions/store/config.actions';
import {AppRoutingModule} from './app-routing.module';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import { NgxPaginationModule } from 'ngx-pagination';
import {CommonModule} from '@angular/common';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {AppComponent} from './app.component';
import { InterceptorService } from './shared/interceptor/interceptor.service';
// BOOTSTRAP COMPONENTS

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {ChartsModule} from 'ng2-charts';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// LAYOUT
import { CompleteLayoutComponent } from './Components/Base/complete-layout/complete-layout.component';
import { PageLayoutComponent } from './Components/Base/page-layout/page-layout.component';
//---
import {BaseLayoutComponent} from './Layout/base-layout/base-layout.component';
import {PagesLayoutComponent} from './Layout/pages-layout/pages-layout.component';

// HEADER
import { HeaderComponent } from './Components/Base/Sections/header/header.component';
import { SearchBoxComponent } from './Components/Base/Sections/header/elements/search-box/search-box.component';
import { UserBoxComponent } from './Components/Base/Sections/header/elements/user-box/user-box.component';
//--
import {Header2Component} from './Layout/Components/header/header2.component';
import {SearchBox2Component} from './Layout/Components/header/elements/search-box/search-box2.component';
import {UserBox2Component} from './Layout/Components/header/elements/user-box/user-box2.component';

// SIDEBAR
import { SidebarComponent } from './Components/Base/Sections/sidebar/sidebar.component';
import { LogoComponent } from './Components/Base/Sections/sidebar/elements/logo/logo.component';
//--
import {Sidebar2Component} from './Layout/Components/sidebar/sidebar2.component';
import {Logo2Component} from './Layout/Components/sidebar/elements/logo/logo2.component';

// FOOTER
import { FooterComponent } from './Components/Base/Sections/footer/footer.component';
//--
import {Footer2Component} from './Layout/Components/footer/footer2.component';

// PAGE-TITLE
import { PageTitleWithButtonsComponent } from './Components/Base/Sections/page-title/page-title-with-buttons/page-title-with-buttons.component';
//--
import {PageTitle2Component} from './Layout/Components/page-title/page-title2.component';

// AUTHENTICATION
import {ForgotPasswordBoxedComponent} from './Components/Authentication/ForgotPassword/forgot-password-boxed.component';
import {LoginBoxedComponent} from './Components/Authentication/Login/login-boxed.component';
import {RegisterBoxedComponent} from './Components/Authentication/Register/register-boxed.component';

// DEMO PAGES

// Dashboards

import {AnalyticsComponent} from './DemoPages/Dashboards/analytics/analytics.component';

// Pages



// Elements

import {StandardComponent} from './DemoPages/Elements/Buttons/standard/standard.component';
import {DropdownsComponent} from './DemoPages/Elements/dropdowns/dropdowns.component';
import {CardsComponent} from './DemoPages/Elements/cards/cards.component';
import {ListGroupsComponent} from './DemoPages/Elements/list-groups/list-groups.component';
import {TimelineComponent} from './DemoPages/Elements/timeline/timeline.component';
import {IconsComponent} from './DemoPages/Elements/icons/icons.component';

// Components

import {AccordionsComponent} from './DemoPages/Components/accordions/accordions.component';
import {TabsComponent} from './DemoPages/Components/tabs/tabs.component';
import {CarouselComponent} from './DemoPages/Components/carousel/carousel.component';
import {ModalsComponent} from './DemoPages/Components/modals/modals.component';
import {ProgressBarComponent} from './DemoPages/Components/progress-bar/progress-bar.component';
import {PaginationComponent} from './DemoPages/Components/pagination/pagination.component';
import {TooltipsPopoversComponent} from './DemoPages/Components/tooltips-popovers/tooltips-popovers.component';

// Tables

import {RegularComponent} from './DemoPages/Tables/regular/regular.component';
import {TablesMainComponent} from './DemoPages/Tables/tables-main/tables-main.component';

// Widgets

import {ChartBoxes3Component} from './DemoPages/Widgets/chart-boxes3/chart-boxes3.component';

// Forms Elements

import {ControlsComponent} from './DemoPages/Forms/Elements/controls/controls.component';
import {LayoutComponent} from './DemoPages/Forms/Elements/layout/layout.component';

// Charts

import {ChartjsComponent} from './DemoPages/Charts/chartjs/chartjs.component';

// Chart.js Examples

import {LineChartComponent} from './DemoPages/Charts/chartjs/examples/line-chart/line-chart.component';
import {BarChartComponent} from './DemoPages/Charts/chartjs/examples/bar-chart/bar-chart.component';
import {ScatterChartComponent} from './DemoPages/Charts/chartjs/examples/scatter-chart/scatter-chart.component';
import {RadarChartComponent} from './DemoPages/Charts/chartjs/examples/radar-chart/radar-chart.component';
import {PolarAreaChartComponent} from './DemoPages/Charts/chartjs/examples/polar-area-chart/polar-area-chart.component';
import {BubbleChartComponent} from './DemoPages/Charts/chartjs/examples/bubble-chart/bubble-chart.component';
import {DynamicChartComponent} from './DemoPages/Charts/chartjs/examples/dynamic-chart/dynamic-chart.component';
import {DoughnutChartComponent} from './DemoPages/Charts/chartjs/examples/doughnut-chart/doughnut-chart.component';
import {PieChartComponent} from './DemoPages/Charts/chartjs/examples/pie-chart/pie-chart.component';
import { InstitutionListComponent } from './Components/Admin/Institution/institution-list/institution-list.component';
import { InstitutionDetailComponent } from './Components/Admin/Institution/institution-detail/institution-detail.component';
import { StudentListComponent } from './Components/Admin/Student/student-list/student-list.component';
import { StudentPersonalInfoComponent } from './Components/Admin/Student/student-personal-info/student-personal-info.component';
import { StudentGameStatusComponent } from './Components/Admin/Student/student-game-status/student-game-status.component';
import { StudentGameProgressComponent } from './Components/Admin/Student/student-game-progress/student-game-progress.component';
import { PageTitleWithoutButtonsComponent } from './Components/Base/Sections/page-title/page-title-without-buttons/page-title-without-buttons.component';
import { PageTitleWithTwoButtonsComponent } from './Components/Base/Sections/page-title/page-title-with-two-buttons/page-title-with-two-buttons.component';
import { UploadListComponent } from './Components/Admin/Upload/upload-list/upload-list.component';
import { ChartGlobal } from './Components/Admin/Charts/chart-global/chart-global.component';
import { HistoricTimeComponent } from './Components/Admin/Charts/chart-global/historic-time/historic-time.component';
import { IntitutionMinutesComponent } from './Components/Admin/Charts/chart-global/intitution-minutes/intitution-minutes.component';
import { AgeMinutesComponent } from './Components/Admin/Charts/chart-global/age-minutes/age-minutes.component';
import { GenderMinutesComponent } from './Components/Admin/Charts/chart-global/gender-minutes/gender-minutes.component';
import { ChartUnitsComponent } from './Components/Admin/Charts/chart-units/chart-units.component';
import { EvaluationsPromComponent } from './Components/Admin/Charts/chart-units/evaluations-avg/evaluations-avg.component';
import { MisionStudentsComponent } from './Components/Admin/Charts/chart-units/mision-students/mision-students.component';
import { MisionTimeComponent } from './Components/Admin/Charts/chart-units/mision-time/mision-time.component';
import { InstitutionComponent } from './Components/Admin/Institution/institution/institution.component';
import { Notfound404Component } from './Components/Utils/404/404.component';
import { ChartClassComponent } from './Components/Admin/Charts/chart-class/chart-class.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [


    // LAYOUT
    CompleteLayoutComponent,
    PageLayoutComponent,
    //--
    AppComponent,
    BaseLayoutComponent,
    PagesLayoutComponent,
    

    // HEADER
    HeaderComponent,
    SearchBoxComponent,
    UserBoxComponent,
    //--
    Header2Component,
    SearchBox2Component,
    UserBox2Component,

    // SIDEBAR
    SidebarComponent,
    LogoComponent,
    //--
    Sidebar2Component,
    Logo2Component,

    // FOOTER
    FooterComponent,
    //--
    Footer2Component,

    // PAGE-TITLE
    PageTitleWithButtonsComponent,
    //--
    PageTitle2Component,
    

    // DEMO PAGES

    // Dashboards

    AnalyticsComponent,

    // User Pages

    ForgotPasswordBoxedComponent,
    LoginBoxedComponent,
    RegisterBoxedComponent,

    // Elements

    StandardComponent,
    IconsComponent,
    DropdownsComponent,
    CardsComponent,
    ListGroupsComponent,
    TimelineComponent,

    // Components

    AccordionsComponent,
    TabsComponent,
    CarouselComponent,
    ModalsComponent,
    ProgressBarComponent,
    PaginationComponent,
    TooltipsPopoversComponent,

    // Tables

    RegularComponent,
    TablesMainComponent,

    // Dashboard Boxes

    ChartBoxes3Component,

    // Form Elements

    ControlsComponent,
    LayoutComponent,

    // CHARTS

    ChartjsComponent,

    // Chart.js Examples

    LineChartComponent,
    BarChartComponent,
    DoughnutChartComponent,
    RadarChartComponent,
    PieChartComponent,
    PolarAreaChartComponent,
    DynamicChartComponent,
    BubbleChartComponent,
    ScatterChartComponent,
    InstitutionListComponent,
    InstitutionDetailComponent,
    StudentListComponent,
    StudentPersonalInfoComponent,
    StudentGameStatusComponent,
    StudentGameProgressComponent,
    PageTitleWithoutButtonsComponent,
    PageTitleWithTwoButtonsComponent,
    UploadListComponent,
    ChartGlobal,
    HistoricTimeComponent,
    IntitutionMinutesComponent,
    AgeMinutesComponent,
    GenderMinutesComponent,
    ChartUnitsComponent,
    EvaluationsPromComponent,
    MisionStudentsComponent,
    MisionTimeComponent,
    InstitutionComponent,
    Notfound404Component,
    ChartClassComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,
    NgxPaginationModule,

    // Angular Bootstrap Components

    PerfectScrollbarModule,
    NgbModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    // Charts

    ChartsModule,
  ],
  providers: [
    {
      provide:
      PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue:
      DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    ConfigActions,
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private ngRedux: NgRedux<ArchitectUIState>,
              private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );

  }
}
