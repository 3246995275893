import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { InstitutionModel } from '../Models/institution.model';
import { ResponseModel_old } from '../Models/response.old.model';
import { InstitutionLicenseModel } from '../Models/institution_license.model';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  url: string = environment.apiURL
  token: string = localStorage.getItem("token");
  headers= new HttpHeaders()
  .set('Content-Type', 'application/vnd.api+json')
  .set('Accept', 'application/vnd.api+json')
  .set('Access-Control-Allow-Origin', '*')  
  .set('Authorization', 'Bearer ' + this.token)

  constructor(private http : HttpClient) { }


  public getLicenses(): Observable<ResponseModel_old> {
    const url= this.url + "/licences"
    return this.http.get<ResponseModel_old>(url, {'headers': this.headers});
  }

  public getLicense(id : number): Observable<ResponseModel_old> {
    const url= this.url + "/licences/" + id
    return this.http.get<ResponseModel_old>(url, {'headers': this.headers});
  }

  public getInstitutionLicense(id : number): Observable<ResponseModel_old> {
    const url= this.url + "/institution_licences/institutions/" + id
    return this.http.get<ResponseModel_old>(url, {'headers': this.headers});
  }

  public createInstitutionLicense(institution_license : InstitutionLicenseModel): Observable<ResponseModel_old> {
    const url= this.url + "/institution_licences/"
    return this.http.put<ResponseModel_old>(url, institution_license, {'headers': this.headers});
  }

  public deleteInstitutionLicense(institutionId : number): Observable<ResponseModel_old> {
    const url= this.url + "/institution_licences/" + institutionId
    return this.http.delete<ResponseModel_old>(url, {'headers': this.headers});
  }

  public updateInstitutionLicense(institution_license : InstitutionLicenseModel): Observable<ResponseModel_old> {
    const url= this.url + "/institution_licences/"
    return this.http.post<ResponseModel_old>(url, institution_license, {'headers': this.headers});
  }

  
}
