<app-page-title-with-buttons (clickEvent)="onClick($event)" [heading]="heading" [subheading]="subheading" [icon]="icon" [action_button]="action_button"></app-page-title-with-buttons>

<!-- Datos institución y busqueda -->
<div class="mb-2">
    <app-search-box (searchEvent)="onSearch($event)"></app-search-box>
    <div class="col-md-4 pl-0" >
        <table class="mb-0 table table-bordered" style="background-color: white;">
          <tbody>
            <tr>
              <th scope="row" style="text-align: left;"><i class=" mr-1 fa fa-university" aria-hidden="true"></i>Número de instituciones:</th>
              <td style="text-align: center;"> 
                {{institutionsCount}} Institucion(es)
              </td>
            </tr>
          </tbody>
        </table>
    </div>
</div>

<div class="main-card mb-3 card">
    <div class="card-header text-center">      
      <h5 class="card-title">INSTITUCIONES</h5>
    </div>
    <div class="table-responsive">
      <table class="align-middle mb-0 table table-borderless table-striped table-hover">
        <thead>
        <tr>
          <th class="text-center">#</th>
          <th class="text-center">Nombre</th>
          <th class="text-center">Opciones</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let institution of institutionsList | paginate: { itemsPerPage: 10, currentPage: page }">
          <td class="text-center text-muted">{{institution.id}}</td>
          <td class="text-center">
            <div class="widget-content p-0">
              <div class="widget-content-wrapper">
                <div class="widget-content-left mr-3">
                </div>
                <div class="widget-content-left flex2">
                  <div class="widget-heading">{{institution.name}}</div>
                </div>
              </div>
            </div>
          </td>
          <td class="text-center">
            <button type="button" class="mr-1 btn btn-success btn-sm" routerLink="/panda/institutions/{{institution.id}}">
                <fa-icon [icon]="plusIcon" class="mr-2"></fa-icon>
               Ver Info
            </button>
            <button type="button" class="mr-1 btn btn-primary btn-sm" routerLink="/panda/institutions/{{institution.id}}/classroom">
                <fa-icon [icon]="usersIcon" class="mr-2"></fa-icon>
               Ver estudiantes
            </button>
            <!-- <button class="mr-1 btn btn-danger btn-sm" (click)="open(content6, institution)">
                <fa-icon [icon]="minusIcon" class="mr-2"></fa-icon>
                Borrar
            </button> -->
          </td>
        </tr>
        </tbody>
      </table>
    </div>    
    <div class="d-block card-footer">
        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="institutionsCount" [(page)]="page" [maxSize]="4"></ngb-pagination>
    </div>
  </div>

<!-- Modal -->
<div class="card-body">
  <ng-template #content6 let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">Borrar institución!</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="text-align: center;">
      <img width="200" src="./assets/images/panda/delete.png" alt="" class="rounded-circle">
      <p>
        ¿Estás seguro que deseas borrar {{institution.name}} ?
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="c('Close click')"><i class="fa fa-ban mr-1" aria-hidden="true"></i>Cerrar</button>
      <button type="button" class="btn btn-danger" (click)="deleteInstitution()"><fa-icon [icon]="minusIcon" class="mr-2"></fa-icon>Si, Borrar</button>
    </div>
  </ng-template>
</div>