<app-page-title-without-buttons [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title-without-buttons>


<div class="mb-2">
    <div class="col-md-4 pl-0" >
        <table class="mb-0 table table-bordered" style="background-color: white;">
        <tbody>
            <tr>
            <th scope="row" style="text-align: left;"><i class=" mr-1 fa fa-info-circle" aria-hidden="true"></i>Unidad: </th>
            <td style="text-align: center;"> 
                <select class="form-control-sm form-control">
                    <option>Selecciona la unidad...</option>
                    <option>Unidad 1</option>
                    <option>Unidad 2</option>
                    <option>Unidad 3</option>
                </select>
            </td>
            </tr>
        </tbody>
        </table>
    </div>
</div>

<div style="display: grid; grid-auto-flow: column ; grid-auto-columns: 50%; gap: 50px;">

    <div class="card main-card mb-5">
        <div class="card-header" style="text-align: center;">
            <h5>Promedio por evaluación</h5>
        </div>
        <div class="card-body">
            <app-evaluations-avg></app-evaluations-avg>
        </div>        
    </div>

    <div class="card main-card mb-5 mr-5">
        <div class="card-header" style="text-align: center;">
            <h5>Estudiantes por misión</h5>
        </div>
        <div class="card-body">
            <app-mision-students></app-mision-students>
        </div>        
    </div>
</div>
<div style="display: grid; grid-auto-flow: column ; grid-auto-columns: 50%; gap: 50px;">

    <div class="card main-card mb-5">
        <div class="card-header" style="text-align: center;">
            <h5>Tiempo por misión</h5>
        </div>
        <div class="card-body">
            <app-mision-time></app-mision-time>
        </div>        
    </div>
</div>