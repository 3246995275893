<app-page-title-without-buttons [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title-without-buttons>

<div class="col-md-6">
<div class="card main-card mb-3">
  <div class="card-header" style="text-align: center;">
    <h5><span contenteditable="true">{{student.attributes.fullName}}</span></h5>
  </div>
  <table class="mb-0 table table-bordered">
    <tbody>
    <tr>
      <th scope="row"><i class="fa fa-fw" aria-hidden="true" title="sitemap"></i> Grado</th>
      <td style="text-align: center;"> 
        {{classroomDescription}}
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="fa fa-fw" aria-hidden="true" title="calendar"></i> Edad</th>
      <td style="text-align: center;">
        {{student.attributes.age}}
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="fa fa-neuter mr-1" aria-hidden="true"></i>Genero </th>
      <td style="text-align: center;">
        {{student.attributes.gender}}
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="fa fa-envelope-o mr-1" aria-hidden="true"></i>Correo</th>
      <td style="text-align: center;">
        {{student.attributes.email}}
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="fa fa-fw" aria-hidden="true" title="calendar"></i> Fecha de nacimiento</th>
      <td style="text-align: center;">
        {{student.attributes.birth}}
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="fa fa-user mr-1" aria-hidden="true"></i>Usuario</th>
      <td style="text-align: center;">
        {{student.attributes.userName}}
      </td>
    </tr>
    <tr>
      <th scope="row"><i class="fa fa-unlock-alt mr-1" aria-hidden="true"></i>Clave</th>
      <td style="text-align: center;">
        <input type="password" class="form-control-sm form-control" style="text-align: center" value="student.attributes.password" disabled >       
      </td>
    </tr>
    </tbody>
  </table>
  <div class="card-header" style="text-align: center;">
    <button class="mr-1 btn btn-primary btn-sm" routerLink="/classrooms/{{student.relationships.classroom.data.id}}">
        <i class="fa fa-arrow-left" aria-hidden="true"></i>
        Volver
    </button>
  </div>
</div>
</div>