import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chart-global',
  templateUrl: './chart-global.component.html',
  styles: [
  ]
})
export class ChartGlobal implements OnInit {

  heading = 'Estadísticas globales'
  subheading = 'Aquí podrás encontrar algúnas estadísticas globales del juego.'
  icon = 'fa fa-bar-chart icon-gradient bg-tempting-azure'
  page = 3

  constructor() { }

  ngOnInit(): void {
  }

}
