import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoginModel } from '../Models/login.model';
import { ResponseModel } from '../Models/response.model';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // url: string = environment.apiURL + "/authenticate"
  // headers= new HttpHeaders().set('Content-Type', 'application/vnd.api+json').set('Accept', 'application/vnd.api+json')

  url: string = environment.apiURL;
  token: string = localStorage.getItem("token");
  headers= new HttpHeaders()
  .set('Content-Type', 'application/vnd.api+json')
  .set('Accept', 'application/vnd.api+json')
  .set('Access-Control-Allow-Origin', '*')  
  .set('Authorization', 'Bearer ' + this.token);
  params= new HttpParams()
  .set('page', '0')
  .set('size', '30');

  constructor(private http : HttpClient) {
  }

  public getAuthenticationToken(form : LoginModel): Observable<ResponseModel> {    
    return this.http.post<ResponseModel>(this.url + "/authenticate", form, { 'headers': this.headers });
  }

  public getUserInfo(username: String): Observable<ResponseModel> {
    this.loadheaders()
    const url= this.url + "/users/username/" + username
    return this.http.get<ResponseModel>(url, {'headers': this.headers});
  }

  loadheaders(){
    this.token = localStorage.getItem("token");
    this.headers= new HttpHeaders()
    .set('Content-Type', 'application/vnd.api+json')
    .set('Accept', 'application/vnd.api+json')
    .set('Access-Control-Allow-Origin', '*')  
    .set('Authorization', 'Bearer ' + this.token);
  }
}
