import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Activity } from 'src/app/Models/NewModels/activity.model';
import { GameProgress } from 'src/app/Models/NewModels/game-progress.model';
import { Mission } from 'src/app/Models/NewModels/mission.model';
import { StudentService } from 'src/app/Services/student.service';

@Component({
  selector: 'app-student-game-progress',
  templateUrl: './student-game-progress.component.html',
  styles: [
  ]
})
export class StudentGameProgressComponent implements OnInit {

  heading = 'Progreso del juego'
  subheading = 'Aquí podrás encontrar la información sobre el progreso del juego del estudiante que seleccionaste.'
  icon = 'fa fa-spinner icon-gradient bg-tempting-azure'
  page = 3
  studentId : number
  institutionId : number
  classroomId : string
  gameProgress = {} as GameProgress
  units = [] as Array<number>
  evaluations = [] as Array<Activity>
  missions = [] as Array<Mission>
  fakeMissions = [] as Array<number>
  fakeEvaluations = [] as Array<number>
  unitTime : number = 0
  unitNumber : number

  constructor(
    private route: ActivatedRoute,
    private studentService: StudentService) { }

  ngOnInit(): void {
    this.loadStudentProgress()
  }

  loadStudentProgress(){
    this.studentId = this.route.snapshot.params['studentId']
    this.institutionId = this.route.snapshot.params['institutionId']
    this.classroomId = this.route.snapshot.params['classroomId']
    this.studentService.getStudentGameProgress(this.studentId).subscribe(response => {
      if(response.errors){
        console.log("Error")
      }
      if(!response.errors){
        this.gameProgress = response.data
        if (this.gameProgress.attributes.unitNumber == 0) {
          this.units.push(1)  
          this.unitNumber = 1 
        }
        for (let index = 0; index < this.gameProgress.attributes.unitNumber; index++) {
          this.units.push(index +1)      
        }
        this.loadProgress(this.gameProgress.attributes.unitNumber)
        this.unitNumber = this.gameProgress.attributes.unitNumber
      }
    })

  }

  loadProgress(unitNumber : number){   
    this.studentService.getStudentGameProgressTime(this.studentId, unitNumber).subscribe(response => {
      if(!response.status){
        console.log("Error")
      }
      if(response.status){
        this.unitTime = !response.response || response.response == 0 ? 0 : Math.round(response.response / 60)
      }
    })

    this.studentService.getStudentGameProgressEvaluations(this.studentId, unitNumber).subscribe(response => {
      if(response.errors){
        console.log("Error")        
        this.fakeEvaluations = []
        for (let index = 0; index < 3; index++) {
          this.fakeEvaluations.push(index);      
        }

      }
      if(!response.errors){
        this.evaluations = response.data
        this.fakeEvaluations = []
        for (let index = this.evaluations.length; index < 3; index++) {
          this.fakeEvaluations.push(index);      
        }
      }
    })

    this.studentService.getStudentGameProgressMissions(this.studentId, unitNumber).subscribe(response => {
      if(response.errors){
        console.log("Error")
      }
      if(!response.errors){
        this.missions = response.data
        this.fakeMissions = []
        for (let index = this.missions.length; index < 6; index++) {
          this.fakeMissions.push(index);      
        }
      }
    })
  }

  changeUnit(unitNumber : number){
    this.loadProgress(unitNumber)
  }

}
