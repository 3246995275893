<div class="d-block">
    <canvas baseChart
            [datasets]="barChartData"
            [labels]="barChartLabels"
            [options]="barChartOptions"
            [plugins]="barChartPlugins"
            [legend]="barChartLegend"
            [chartType]="barChartType">
    </canvas>
  </div>
  <div class="divider"></div>
  <div class="text-center">
  </div>
  