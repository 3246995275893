import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faInfo, faSearch, faTrash, faUsers } from '@fortawesome/free-solid-svg-icons';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ClassroomModel } from 'src/app/Models/classroom.model';
import { UserModel } from 'src/app/Models/NewModels/user.model';
import { ResponseModelWithPages } from 'src/app/Models/response.model';
import { InstitutionService } from 'src/app/Services/institution.service';
import { StudentService } from 'src/app/Services/student.service';

@Component({
  selector: 'app-student-list',
  templateUrl: './student-list.component.html',
  styles: [
  ]
})
export class StudentListComponent implements OnInit {

  heading = 'Panel administrativo - Estudiantes'
  subheading = 'Aquí podrás administrar los estudiantes que tienen acceso a panda.'
  action_button = 'Crear Estudiante'
  action_button_plus = 'Cargar archivo de Estudiantes'
  icon = 'fa fa-users icon-gradient bg-tempting-azure'
  plusIcon = faInfo
  minusIcon = faTrash
  searchIcon = faSearch
  usersIcon = faUsers
  page = 0
  closeResult: string;
  classroomList = [] as ClassroomModel []
  studentList = [] as UserModel []
  classroomDescription : string
  classroomId : string = "Select classroom"
  totalStudents : number = 0
  userId: number
  
  constructor(private modalService: NgbModal, private route: ActivatedRoute, private router: Router, private institutionService: InstitutionService, private studentService: StudentService) { 
    
  }

  ngOnInit(): void {
      this.userId = parseInt(localStorage.getItem("userId"))
      this.loadClassRooms()  
  }

  loadClassRooms(){ 
    this.institutionService.getInstitutionClassRoomsByTeacher(this.userId).subscribe(response => {
      if(!response.status){
        console.log("Error")
      }
      if(response.status){
        this.classroomList = response.response
        if ( this.classroomList[0] != undefined) {
          localStorage.setItem("institutionId", this.classroomList[0].institution.id.toString())
        }else{
          localStorage.setItem("institutionId", "0")
        }        
        if (typeof this.route.snapshot.params['classroomId'] !== 'undefined') {
          this.classroomId = this.route.snapshot.params['classroomId']
          this.changeClassroom()
        }
      }
    })
  }

  changeClassroom(){    
    this.classroomList.forEach(classroom => {
      if (classroom.id.toString() == this.classroomId) {
        this.classroomDescription = classroom.grade.description
      }
    });
    this.page = 0
    this.loadStudentList()
  }

  loadStudentList(){
    if (this.classroomId != "Select classroom") {
      this.studentService.getStudentsByClassroom(this.classroomId, this.page - 1).subscribe(response => {
        if(response.errors){
        }
        if(!response.errors){
          this.studentList = response.data
          this.totalStudents = response.meta.page.totalElements
        }
      })
    }else{
      this.studentList = null
      this.totalStudents = 0
    }    
  }

  onSearch(text : String){

  }

  onClickFileLoad(text : String){

  }

  openDeleteModal(content) {
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openUploadModal(content) {
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
