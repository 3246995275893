import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let request = req;

    // if (token) {
    //   request = req.clone({
    //     setHeaders: {
    //       test: `Bearer ${ token }`
    //     }
    //   });
    // }
    
    if (localStorage.getItem('expiration') != null ) {
      
      var expiration = localStorage.getItem('expiration')
      var expirationFormated =  expiration.substring(0,10) +" "+  expiration.substring(11,19)
      var expirationDate = new Date(expirationFormated);
      var todayDate = new Date();

      if ((expirationDate.getTime() - todayDate.getTime()) < 0) {
        this.router.navigate(["/"])
        localStorage.clear();
      }    
    }

    return  next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {

        if (err.status === 401) {
          this.router.navigate(['/login']);
        }

        return throwError( err );

      })
    );
  }
}
