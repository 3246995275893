import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './shared/guards/auth.guard';

import {BaseLayoutComponent} from './Layout/base-layout/base-layout.component';
import { PageLayoutComponent } from './Components/Base/page-layout/page-layout.component';

// DEMO PAGES

// Dashboards

import {AnalyticsComponent} from './DemoPages/Dashboards/analytics/analytics.component';

// Pages

import {ForgotPasswordBoxedComponent} from './Components/Authentication/ForgotPassword/forgot-password-boxed.component';
import {LoginBoxedComponent} from './Components/Authentication/Login/login-boxed.component';
import {RegisterBoxedComponent} from './Components/Authentication/Register/register-boxed.component';

// Elements

import {StandardComponent} from './DemoPages/Elements/Buttons/standard/standard.component';
import {DropdownsComponent} from './DemoPages/Elements/dropdowns/dropdowns.component';
import {CardsComponent} from './DemoPages/Elements/cards/cards.component';
import {ListGroupsComponent} from './DemoPages/Elements/list-groups/list-groups.component';
import {TimelineComponent} from './DemoPages/Elements/timeline/timeline.component';
import {IconsComponent} from './DemoPages/Elements/icons/icons.component';

// Components

import {AccordionsComponent} from './DemoPages/Components/accordions/accordions.component';
// import {TabsComponent} from './DemoPages/Components/tabs/tabs.component';
import {CarouselComponent} from './DemoPages/Components/carousel/carousel.component';
import {ModalsComponent} from './DemoPages/Components/modals/modals.component';
import {ProgressBarComponent} from './DemoPages/Components/progress-bar/progress-bar.component';
import {PaginationComponent} from './DemoPages/Components/pagination/pagination.component';
import {TooltipsPopoversComponent} from './DemoPages/Components/tooltips-popovers/tooltips-popovers.component';

// Tables

import {TablesMainComponent} from './DemoPages/Tables/tables-main/tables-main.component';

// Widgets

import {ChartBoxes3Component} from './DemoPages/Widgets/chart-boxes3/chart-boxes3.component';

// Forms Elements

import {ControlsComponent} from './DemoPages/Forms/Elements/controls/controls.component';
import {LayoutComponent} from './DemoPages/Forms/Elements/layout/layout.component';

// Charts

import {ChartjsComponent} from './DemoPages/Charts/chartjs/chartjs.component';
import { CompleteLayoutComponent } from './Components/Base/complete-layout/complete-layout.component';
import { InstitutionListComponent } from './Components/Admin/Institution/institution-list/institution-list.component';
import { InstitutionDetailComponent } from './Components/Admin/Institution/institution-detail/institution-detail.component';
import { StudentListComponent } from './Components/Admin/Student/student-list/student-list.component';
import { StudentPersonalInfoComponent } from './Components/Admin/Student/student-personal-info/student-personal-info.component';
import { StudentGameStatusComponent } from './Components/Admin/Student/student-game-status/student-game-status.component';
import { StudentGameProgressComponent } from './Components/Admin/Student/student-game-progress/student-game-progress.component';
import { UploadListComponent } from './Components/Admin/Upload/upload-list/upload-list.component';
import { ChartGlobal } from './Components/Admin/Charts/chart-global/chart-global.component';
import { ChartUnitsComponent } from './Components/Admin/Charts/chart-units/chart-units.component';
import { InstitutionComponent } from './Components/Admin/Institution/institution/institution.component';
import { ChartClassComponent } from './Components/Admin/Charts/chart-class/chart-class.component';
import { Notfound404Component } from './Components/Utils/404/404.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: BaseLayoutComponent,
  //   children: [

  //     // Dashboads

  //     {path: '', component: AnalyticsComponent, data: {extraParameter: 'dashboardsMenu'}},

  //     // Elements

  //     {path: 'elements/buttons-standard', component: StandardComponent, data: {extraParameter: 'elementsMenu'}},
  //     {path: 'elements/dropdowns', component: DropdownsComponent, data: {extraParameter: 'elementsMenu'}},
  //     {path: 'elements/icons', component: IconsComponent, data: {extraParameter: 'elementsMenu'}},
  //     {path: 'elements/cards', component: CardsComponent, data: {extraParameter: 'elementsMenu'}},
  //     {path: 'elements/list-group', component: ListGroupsComponent, data: {extraParameter: 'elementsMenu'}},
  //     {path: 'elements/timeline', component: TimelineComponent, data: {extraParameter: 'elementsMenu'}},

  //     // Components

  //     // {path: 'components/tabs', component: TabsComponent, data: {extraParameter: 'componentsMenu'}},
  //     {path: 'components/accordions', component: AccordionsComponent, data: {extraParameter: 'componentsMenu'}},
  //     {path: 'components/modals', component: ModalsComponent, data: {extraParameter: 'componentsMenu'}},
  //     {path: 'components/progress-bar', component: ProgressBarComponent, data: {extraParameter: 'componentsMenu'}},
  //     {path: 'components/tooltips-popovers', component: TooltipsPopoversComponent, data: {extraParameter: 'componentsMenu'}},
  //     {path: 'components/carousel', component: CarouselComponent, data: {extraParameter: 'componentsMenu'}},
  //     {path: 'components/pagination', component: PaginationComponent, data: {extraParameter: 'componentsMenu'}},

  //     // Tables

  //     {path: 'tables/bootstrap', component: TablesMainComponent, data: {extraParameter: 'tablesMenu'}},

  //     // Widgets

  //     {path: 'widgets/chart-boxes-3', component: ChartBoxes3Component, data: {extraParameter: 'pagesMenu3'}},

  //     // Forms Elements

  //     {path: 'forms/controls', component: ControlsComponent, data: {extraParameter: 'formElementsMenu'}},
  //     {path: 'forms/layouts', component: LayoutComponent, data: {extraParameter: 'formElementsMenu'}},

  //     // Charts

  //     {path: 'charts/chartjs', component: ChartjsComponent, data: {extraParameter: ''}},

  //   ]

  // },
  {
    path: '',
    component: PageLayoutComponent,
    children: [

      //Authentication component
      {path: 'login', component: LoginBoxedComponent, data: {extraParameter: ''}},
      {path: 'register', component: RegisterBoxedComponent, data: {extraParameter: ''}},
      {path: 'forgot-password', component: ForgotPasswordBoxedComponent, data: {extraParameter: ''}},
      {path: '', component: LoginBoxedComponent, data: {extraParameter: ''}},
      {path: 'pagina-no-encontrada', component: Notfound404Component, data: {extraParameter: ''}},
    ]
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: CompleteLayoutComponent,
    children: [

      {path: 'institutions', component: InstitutionListComponent, data: {extraParameter: ''}},
      {path: 'institutions/create', component: InstitutionComponent, data: {extraParameter: ''}},
      {path: 'institutions/:institutionId', component: InstitutionDetailComponent, data: {extraParameter: ''}},
      {path: 'institution', component: InstitutionDetailComponent, data: {extraParameter: ''}},
      {path: 'classrooms', component: StudentListComponent, data: {userId: localStorage.getItem('userId'), extraParameter: 'institionsMenu'}},
      {path: 'classrooms/:classroomId', component: StudentListComponent, data: {userId: localStorage.getItem('userId'), extraParameter: ''}},
      {path: 'classrooms/:classroomId/students/:studentId/personal-information', component: StudentPersonalInfoComponent, data: {extraParameter: ''}},
      {path: 'classrooms/:classroomId/students/:studentId/game-status', component: StudentGameStatusComponent, data: {extraParameter: ''}},
      {path: 'classrooms/:classroomId/students/:studentId/game-progress', component: StudentGameProgressComponent, data: {extraParameter: ''}},
      {path: 'statistics', component: ChartClassComponent, data: {userId: localStorage.getItem('userId'), extraParameter: ''}},
      // {path: 'uploads', component: UploadListComponent, data: {extraParameter: ''}},
      // {path: 'chart-global', component: ChartGlobal, data: {extraParameter: ''}},
      // {path: 'chart-units', component: ChartUnitsComponent, data: {extraParameter: ''}},
      
    ]
  },
  {path: '**', redirectTo: 'pagina-no-encontrada'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
})],
providers: [AuthGuard],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
