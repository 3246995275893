<app-page-title-without-buttons [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title-without-buttons>

<div class="card main-card mb-5">
    <div class="card-header" style="text-align: center;">
        <h5>Minutos jugados en Panda</h5>
    </div>
    <app-historic-time></app-historic-time>
</div>

<div style="display: grid; grid-auto-flow: column ; grid-auto-columns: 50%; gap: 50px;">

    <div class="card main-card mb-5">
        <div class="card-header" style="text-align: center;">
            <h5>Minutos por institución</h5>
        </div>
        <div class="card-body">
            <app-intitution-minutes></app-intitution-minutes>
        </div>        
    </div>

    <div class="card main-card mb-5 mr-5">
        <div class="card-header" style="text-align: center;">
            <h5>Minutos por edad</h5>
        </div>
        <div class="card-body">
            <app-age-minutes></app-age-minutes>
        </div>
        
    </div>
</div>

<div class="card main-card mb-5">
    <div class="card-header" style="text-align: center;">
        <h5>Minutos por genero</h5>
    </div>
    <div class="card-body">
        <app-gender-minutes></app-gender-minutes>
    </div>
    
</div>