import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faInfo, faSearch, faTrash, faUsers } from '@fortawesome/free-solid-svg-icons';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { InstitutionModel } from 'src/app/Models/institution.model';
import { InstitutionLicenseModel } from 'src/app/Models/institution_license.model';
import { LicenseModel } from 'src/app/Models/license.model';
import { InstitutionService } from 'src/app/Services/institution.service';
import { LicenseService } from 'src/app/Services/license.service';

@Component({
  selector: 'app-institution',
  templateUrl: './institution.component.html',
  styles: []
})
export class InstitutionComponent implements OnInit {
  heading = 'Crear nueva institución'
  subheading = 'Aquí podrás crear una nueva institución.'
  icon = 'pe-7s-culture icon-gradient bg-tempting-azure'
  plusIcon = faInfo
  minusIcon = faTrash
  searchIcon = faSearch
  usersIcon = faUsers
  page = 3
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  institution : InstitutionModel = {
    id: 0,
    name: '',
    lastUpdate: ''
  }
  institution_license : InstitutionLicenseModel = {
    id: 0,
    institution: {
      id : 0
    },
    licence: {
        id : 0
    },
    initialDate : ''
  }  

  licenseList : LicenseModel []

  constructor(private institutionService: InstitutionService, private licenseService: LicenseService, private route: Router) {
   }

  ngOnInit(): void {
    this.licenseService.getLicenses().subscribe(response => {          
      if(!response.status){
      }
      if(response.status){
        this.licenseList = response.response
      }
    }) 
  }

  onCreate(): void {   
    this.institutionService.createInstitution(this.institution).subscribe(response => {          
      if(!response.status){
      }
      if(response.status){
        this.institution_license.institution.id = response.response.id        
        this.licenseService.createInstitutionLicense(this.institution_license).subscribe(response => {          
          if(!response.status){
          }
          if(response.status){      
            this.route.navigate(["/panda/institutions"])        
          }
        }) 
      }
    })
  }
}
