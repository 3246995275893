<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="icon {{icon}}"></i>
      </div>
      <div>
        {{heading}}
        <div
          class="page-title-subheading">
          {{subheading}}
        </div>
      </div>
    </div>
    <div class="page-title-actions">
      <!-- <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success" (click)="onClick()">
        <fa-icon [icon]="faPlus" class="mr-2"></fa-icon>
        {{action_button}}
      </button> -->
    </div>
  </div>
</div>
