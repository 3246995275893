<app-page-title-without-buttons [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title-without-buttons>

<div class="col-md-6">
  <div class="card main-card mb-3">
    <div class="card-header" style="text-align: center;">
      <h5>Estadísticas</h5>
    </div>
    <table class="mb-0 table table-bordered">
      <tbody>
      <tr>
        <th scope="row"><i class="fa fa-sign-in mr-1" aria-hidden="true"></i>Número total de ingresos</th>
        <td style="text-align: center;"> 
          {{loginNumber}}
        </td>
      </tr>
      <tr>
        <th scope="row"><i class="fa fa-clock-o mr-1" aria-hidden="true"></i>Tiempo total de juego (minutos)</th>
        <td style="text-align: center;">
          {{timePlayed}} min
        </td>
      </tr>
      <tr>
        <th scope="row"><i class="fa fa-fw mr-1" aria-hidden="true" title="calendar"></i> Fecha último ingreso</th>
        <td style="text-align: center;">
          {{loginLastDate}}
        </td>
      </tr>
      <tr>
        <th scope="row"><i class="fa fa-desktop mr-1" aria-hidden="true"></i>Plataforma último ingreso</th>
        <td style="text-align: center;">
          {{loginPlatform}}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="col-md-6">
    <div class="card main-card mb-3">
      <div class="card-header" style="text-align: center;">
        <h5>Estado actual</h5>
      </div>
      <table class="mb-0 table table-bordered">
        <tbody>
        <tr>
          <th scope="row"><i class="fa fa-money mr-1" aria-hidden="true"></i>Número de monedas</th>
          <td style="text-align: center;"> 
            {{gameStatus?.attributes?.coin}}
          </td>
        </tr>
        <tr>
          <th scope="row"><i class="fa fa-heartbeat mr-1" aria-hidden="true"></i>Salud actual</th>
          <td style="text-align: center;">
            {{gameStatus?.attributes?.health}}
          </td>
        </tr>
        <tr>
          <th scope="row"><i class="fa fa-info-circle mr-1" aria-hidden="true"></i>Unidad actual </th>
          <td style="text-align: center;">
            {{gameProgress?.attributes?.unitNumber}}
          </td>
        </tr>
        <tr>
          <th scope="row"><i class="fa fa-info-circle mr-1" aria-hidden="true"></i>Misión actual</th>
          <td style="text-align: center;">
            {{gameProgress?.attributes?.missionNumber}}
          </td>
        </tr>
        <tr>
          <th scope="row"><i class="fa fa-info-circle" aria-hidden="true"></i> Actividad actual</th>
          <td style="text-align: center;">
            {{gameProgress?.attributes?.activityNumber}}
          </td>
        </tr>
        </tbody>
      </table>
      <div class="card-header" style="text-align: center;">
        <button class="mr-1 btn btn-primary btn-sm" routerLink="/classrooms/{{classroomId}}">
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Volver
        </button>
      </div>
    </div>
</div>