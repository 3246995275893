import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GameProgress } from 'src/app/Models/NewModels/game-progress.model';
import { GameStatus } from 'src/app/Models/NewModels/game-status.model';
import { InstitutionService } from 'src/app/Services/institution.service';
import { StudentService } from 'src/app/Services/student.service';

@Component({
  selector: 'app-student-game-status',
  templateUrl: './student-game-status.component.html',
  styles: [
  ]
})
export class StudentGameStatusComponent implements OnInit {

  heading = 'Estado del juego'
  subheading = 'Aquí podrás encontrar la información sobre el estado actual del juego del estudiante que seleccionaste.'
  icon = 'fa fa-line-chart icon-gradient bg-tempting-azure'
  page = 3
  studentId : number
  institutionId : number
  classroomId : string
  loginNumber : number = 0
  loginPlatform : String = "NA"
  loginLastDate : String = ""
  timePlayed : number
  //gameStatus = {} as GameStatus
  //gameStatus: GameStatus = {} as GameStatus
  gameStatus = new GameStatus()
  gameProgress = {} as GameProgress
  
  
  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private studentService: StudentService,
    private institutionService: InstitutionService) { }

  ngOnInit(): void {
    this.gameStatus = new GameStatus()
    this.loadStudentGmeStatus()
  }

  loadStudentGmeStatus(){
    this.studentId = this.route.snapshot.params['studentId']
    this.institutionId = this.route.snapshot.params['institutionId']
    this.classroomId = this.route.snapshot.params['classroomId']
    this.studentService.getStudentGameStatus(this.studentId).subscribe(response => {
      if(response.errors){
        console.log("Error")
      }
      if(!response.errors){
        this.gameStatus = response.data
      }
    })

    this.studentService.getStudentGameProgress(this.studentId).subscribe(response => {
      if(response.errors){
        console.log("Error")
      }
      if(!response.errors){
        this.gameProgress = response.data
      }
    })

    this.studentService.getStudentGameStatusTime(this.studentId).subscribe(response => {
      if(!response.status){
        console.log("Error")
      }
      if(response.status){
        this.timePlayed = !response.response || response.response == 0 ? 0 : Math.round(response.response / 60)
      }
    })

    this.studentService.getLoginTraceCountByUser(this.studentId).subscribe(response => {
      if(!response.status){
        console.log("Error")
      }
      if(response.status){
        this.loginNumber = response.response
      }
    })

    this.studentService.getLastLoginTrace(this.studentId).subscribe(response => {
      if(response.errors){
        console.log("Error")
      }
      if(!response.errors){
        if (response.data.length > 0) {
          this.loginPlatform = response.data[0].attributes.origin
          this.loginLastDate = response.data[0].attributes.loginDate
        }else{
          this.loginPlatform = "NA"
          this.loginLastDate = "NA"
        }
        
      }
    })
  }

}
