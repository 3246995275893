<div class="chart">
    <canvas baseChart
            [data]="pieChartData"
            [labels]="pieChartLabels"
            [chartType]="pieChartType"
            [options]="pieChartOptions"
            [plugins]="pieChartPlugins"
            [colors]="pieChartColors"
            [legend]="pieChartLegend">
    </canvas>
  </div>
  <div class="divider"></div>