import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { InstitutionModel } from '../Models/institution.model';
import { ResponseModel_old } from '../Models/response.old.model';
import { ResponseModel, ResponseModelWithPages } from '../Models/response.model';

@Injectable({
    providedIn: 'root'
  })

export class StudentService {
    
    url: string = environment.apiURL;
    token: string = localStorage.getItem("token");
    headers= new HttpHeaders()
    .set('Content-Type', 'application/vnd.api+json')
    .set('Accept', 'application/vnd.api+json')
    .set('Access-Control-Allow-Origin', '*')  
    .set('Authorization', 'Bearer ' + this.token);
    params= new HttpParams()
    .set('page', '0')
    .set('size', '30');
  
    constructor(private http : HttpClient) { }  
  
    public getStudentsByClassroom(classroomId: string, page : number): Observable<ResponseModelWithPages> {
      const url= this.url + "/users/classroom/"+classroomId+"?page="+page+"&size=10"
      return this.http.get<ResponseModelWithPages>(url, {'headers': this.headers});
    }

    public getStudentPersonalInformation(studentId: number): Observable<ResponseModel> {
      const url= this.url + "/users/"+studentId
      return this.http.get<ResponseModel>(url, {'headers': this.headers});
    }

    public getStudentGameStatus(studentId: number): Observable<ResponseModel> {
      const url= this.url + "/students/"+ studentId + "/game-status"
      return this.http.get<ResponseModel>(url, {'headers': this.headers});
    }

    public getStudentGameStatusTime(studentId: number): Observable<ResponseModel_old> {
      const url= this.url + "/students/"+ studentId + "/game-status/time"
      return this.http.get<ResponseModel_old>(url, {'headers': this.headers});
    }

    public getStudentGameProgress(studentId: number): Observable<ResponseModel> {
      const url= this.url + "/students/"+ studentId + "/game-progress"
      return this.http.get<ResponseModel>(url, {'headers': this.headers});
    }

    public getStudentGameProgressTime(studentId: number, unitNumber: number): Observable<ResponseModel_old> {
      const url= this.url + "/students/"+ studentId + "/game-progress/time/unit/" + unitNumber
      return this.http.get<ResponseModel_old>(url, {'headers': this.headers});
    }

    public getStudentGameProgressEvaluations(studentId: number, unitNumber: number): Observable<ResponseModelWithPages> {
      const url= this.url + "/students/"+ studentId + "/game-progress/evaluations/unit/" + unitNumber + "?page=0&size=3"
      return this.http.get<ResponseModelWithPages>(url, {'headers': this.headers});
    }

    public getStudentGameProgressMissions(studentId: number, unitNumber: number): Observable<ResponseModelWithPages> {
      const url= this.url + "/students/"+ studentId + "/game-progress/missions/unit/" + unitNumber + "?page=0&size=6"
      return this.http.get<ResponseModelWithPages>(url, {'headers': this.headers});
    }

    public getLoginTraceCountByUser(studentId: number): Observable<ResponseModel_old> {
      const url= this.url + "/users/"+studentId+"/logintraces/count"
      return this.http.get<ResponseModel_old>(url, {'headers': this.headers});
    }

    public getLastLoginTrace(studentId: number): Observable<ResponseModelWithPages> {
      const url= this.url + "/users/"+studentId+"/logintraces?page=0&size=1"
      return this.http.get<ResponseModelWithPages>(url, {'headers': this.headers});
    }

    public getStudentGameProgressTimeLine(classroomId: String): Observable<ResponseModel_old> {
      const url= this.url + "/students/all/game-progress/classrooms/" + classroomId + "/minutes/week"
      return this.http.get<ResponseModel_old>(url, {'headers': this.headers});
    }

    public getStudentGameProgressCountByUnit(classroomId: String): Observable<ResponseModel_old> {
      const url= this.url + "/students/all/game-progress/classrooms/" + classroomId + "/units/student/count"
      return this.http.get<ResponseModel_old>(url, {'headers': this.headers});
    }

    public getStudentGameProgressTopByUnit(classroomId: String): Observable<ResponseModel_old> {
      const url= this.url + "/students/all/game-progress/classrooms/" + classroomId + "/topByUnit"
      return this.http.get<ResponseModel_old>(url, {'headers': this.headers});
    }

    public getStudentGameProgressTopByCoin(classroomId: String): Observable<ResponseModel_old> {
      const url= this.url + "/students/all/game-progress/classrooms/" + classroomId + "/topByCoins"
      return this.http.get<ResponseModel_old>(url, {'headers': this.headers});
    }

}
