import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chart-units',
  templateUrl: './chart-units.component.html',
  styles: [
  ]
})
export class ChartUnitsComponent implements OnInit {

  heading = 'Estadísticas por unidad'
  subheading = 'Aquí podrás encontrar algúnas estadísticas por unidad del juego.'
  icon = 'fa fa-bar-chart icon-gradient bg-tempting-azure'
  page = 3

  constructor() { }

  ngOnInit(): void {
  }

}
