import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faInfo, faSearch, faTrash, faUsers } from '@fortawesome/free-solid-svg-icons';
import { InstitutionModel } from 'src/app/Models/institution.model';
import { InstitutionLicenseModel } from 'src/app/Models/institution_license.model';
import { LicenseModel } from 'src/app/Models/license.model';
import { InstitutionService } from 'src/app/Services/institution.service';
import { LicenseService } from 'src/app/Services/license.service';

@Component({
  selector: 'app-institution-detail',
  templateUrl: './institution-detail.component.html',
  styles: [
  ]
})
export class InstitutionDetailComponent implements OnInit {

  heading = 'Información de la institución'
  subheading = 'Aquí podrás encontrar la información detallada de la institución que seleccionaste.'
  icon = 'pe-7s-culture icon-gradient bg-tempting-azure'
  plusIcon = faInfo
  minusIcon = faTrash
  searchIcon = faSearch
  usersIcon = faUsers
  page = 3
  gradesNumber : number = 0
  classroomsNumber : number = 0
  studentsNumber : number = 0
  teachersNumber : number = 0
  licenseList : LicenseModel []
  institution : InstitutionModel = {
    id: 0,
    name: '',
    lastUpdate: ''
  } 
  institution_license : InstitutionLicenseModel = {
    id: 0,
    institution: {
      id : 0
    },
    licence: {
        id : 0
    },
    initialDate : '',
    endDate : ''
  } 
  license : LicenseModel = {
    id: 0,
    description: '',
    type: '',
    duration: 0,
    lastUpdate: ''
  } 
  
  constructor(private institutionService: InstitutionService, private route: ActivatedRoute, private router: Router, private licenseService: LicenseService) { }

  ngOnInit(): void {
    this.loadInstitutionDetails()     
  }

  loadInstitutionDetails(){
    this.institutionService.getInstitution(parseInt(localStorage.getItem("institutionId"))).subscribe(response => {          
      if(!response.status){
      }
      if(response.status){
        
        this.institution = response.response
        this.licenseService.getInstitutionLicense(this.institution.id).subscribe(response => {          
          if(!response.status){
          }    
          if(response.status && typeof response.response[0] !== 'undefined'){
            this.institution_license = response.response[0]
            this.institution_license.initialDate = this.institution_license.initialDate.split("T")[0]
            this.institution_license.endDate = this.institution_license.endDate.split("T")[0]
            this.licenseService.getLicense(this.institution_license.licence.id).subscribe(response => {  
              if(!response.status){
              }        
              if(response.status){
                this.license = response.response
                this.licenseService.getLicenses().subscribe(response => {          
                  if(!response.status){
                  }
                  if(response.status){
                    this.licenseList = response.response
                  }
                }) 
              }
            })
          }
        })
        
        this.institutionService.getClassroomCount(parseInt(localStorage.getItem("institutionId"))).subscribe(response => { 
          if(response.status){
            this.classroomsNumber = response.response
          }
        })

        this.institutionService.getClassroomCountByGrade(parseInt(localStorage.getItem("institutionId"))).subscribe(response => { 
          if(response.status){
            this.gradesNumber = response.response
          }
        })

        this.institutionService.getClassroomCountByStudent(parseInt(localStorage.getItem("institutionId"))).subscribe(response => { 
          if(response.status){
            this.studentsNumber = response.response
          }
        })

        this.institutionService.getClassroomCountByTeacher(parseInt(localStorage.getItem("institutionId"))).subscribe(response => { 
          if(response.status){
            this.teachersNumber = response.response
          }
        })
        
      }
    })
  }

  changeInstitutionName(event: any) {
    this.institution.name = event.target.textContent
  }

  changeInitialDate(event: any) {
    this.institution_license.initialDate = event.target.textContent
  }

  updateInstitutionAndHisLicense(){
    this.institutionService.updateInstitution(this.institution).subscribe(response => {
      if(!response.status){
      }
      if(response.status){
        this.licenseService.updateInstitutionLicense(this.institution_license).subscribe( response => {
          if(!response.status){
          }
          if(response.status){
            this.router.navigate(["/classrooms"])
          }
        })        
      }
    })

  }

}

