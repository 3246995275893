<app-page-title-with-two-buttons [heading]="heading" [subheading]="subheading" [icon]="icon" [action_button]="action_button" 
[action_button_plus]="action_button_plus" (clickEvent)="onClickFileLoad($event)">
 
</app-page-title-with-two-buttons>

<!-- Datos institución y busqueda -->
<div class="mb-2">
    <div class="col-md-6 pl-0" >
        <table class="mb-0 table table-bordered" style="background-color: white;">
          <tbody>
            <tr>
              <th scope="row" style="text-align: left;"><fa-icon [icon]="usersIcon" class="mr-2"></fa-icon>Selecciona la clase:</th>
              <td style="text-align: center;">  
                <!-- [(ngModel)]="classroomId"   (change)="changeClassroom($event.target.value)"     -->
                <select class="form-control-sm form-control" [(ngModel)]="classroomId" style="text-align: center" (change)="changeClassroom()">
                  <option>Select classroom</option>
                  <option *ngFor="let classroom of classroomList" value="{{classroom.id}}">{{classroom.grade.description}}</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
</div>

<div class="row">
  <div class="col-md-6">
  <!-- <div class="row h-50"> -->
  <div class="card main-card">
    <div class="card-header" style="text-align: center;">
      Total de minutos jugados esta semana
    </div>
    <div class="card-body">
      <div class="d-block">
        <canvas baseChart width="400" height="200"
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [colors]="lineChartColors"
                [legend]="lineChartLegend"
                [chartType]="lineChartType"
                [plugins]="lineChartPlugins"
                (chartHover)="chartHovered($event)"
                (chartClick)="chartClicked($event)"></canvas>
      </div>
      <div class="table-responsive">
        <table class="table mt-3 table-condensed table-hover table-bordered">
          <thead>
          <tr>
            <th *ngFor="let label of lineChartLabels">{{label}}</th>
          </tr>
          </thead>
          <tr *ngFor="let d of lineChartData; let i=index" [class]="'line-'+i">
            <td *ngFor="let label of lineChartLabels; let j=index">{{d && d.data[j]}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
<!-- </div> -->
  </div>

  <div class="col-md-6">
  <div class="card main-card">
    <div class="card-header" style="text-align: center;">
      Total de estudiantes por unidad
    </div>
    <div class="card-body">
      <div class="chart">
        <canvas baseChart
                [data]="pieChartData"
                [labels]="pieChartLabels"
                [chartType]="pieChartType"
                [options]="pieChartOptions"
                [plugins]="pieChartPlugins"
                [colors]="pieChartColors"
                [legend]="pieChartLegend">
        </canvas>
      </div>
      <div class="divider mb-12"></div>
      <div class="text-center mb-12">
      </div>
    </div>
  </div>
  </div>
</div>


<div class="row mt-4">
  <div class="col-md-6">
  <!-- <div class="row h-50"> -->
  <div class="card main-card">
    <div class="card-header" style="text-align: center;">
      Ranking por Unidad
    </div>
    <div class="card-body">
      <table class="mb-0 table table-bordered" style="text-align: center;">
        <thead >
            <th>Nombre</th>
            <th>Unidad</th>
        </thead>
        <tbody>
        <tr *ngFor="let unit of unitTop; let i=index">
            <td style="text-align: center;"> {{unit.key}} </td>
            <td style="text-align: center;"> {{unit.val}} </td>
        </tr>         
        </tbody>
      </table>
    </div>
  </div>

  <div class="card main-card mt-4">
    <div class="card-header" style="text-align: center;">
      Ranking por Errores
    </div>
    <div class="card-body">
      <table class="mb-0 table table-bordered" style="text-align: center;">
        <thead >
            <th>Nombre</th>
            <th>Errores</th>
        </thead>
        <tbody>
          <tr>
            <!-- <td style="text-align: center;"> Pepito 1 </td>
            <td style="text-align: center;"> 3 </td> -->
        </tr> 
        <!-- <tr *ngFor="let mission of missions; let i=index">
            <td style="text-align: center;" *ngIf="i == 0"> Práctica </td>
            <td style="text-align: center;" *ngIf="i != 0"> Misión {{i}} </td>
            <td style="text-align: center;"> {{mission.attributes.time}} </td>
            <td style="text-align: center;"> {{mission.attributes.errorsNumber}} </td>
        </tr>          -->
        </tbody>
    </table>
    </div>
  </div>
<!-- </div> -->
  </div>

  <div class="col-md-6">
  <div class="card main-card">
    <div class="card-header" style="text-align: center;">
      Ranking por monedas
    </div>
    <div class="card-body">
      <table class="mb-0 table table-bordered" style="text-align: center;">
        <thead >
            <th>Nombre</th>
            <th>Monedas</th>
        </thead>
        <tbody>
        <tr *ngFor="let coin of CoinTop; let i=index">
            <td style="text-align: center;"> {{coin.key}} </td>
            <td style="text-align: center;"> {{coin.val}} </td>
        </tr>         
        </tbody>
    </table>
    </div>
  </div>
  </div>
</div>


