export interface IGameStatus{
    id: string;
    type: string;
    attributes: {
        coin: number,
        health: number,
        avatar: number,
        lastUpdate: string
    };
    relationships: {
        user: {
            data: {
                id: string,
                type: string
            }
        }
    }    
}

export class GameStatus implements IGameStatus{
    id: string;
    type: string;
    attributes: { coin: number; health: number; avatar: number; lastUpdate: string; };
    relationships: { user: { data: { id: string; type: string; }; }; };

    GameStatus(){
        this.id = "0";
        this.type = "";
        this.attributes = { coin:0, health:0, avatar:0, lastUpdate: "" };
        this.relationships= { user: { data: { id:"0", type: "" } } }
    }

}